<template>
    <booking-create :locations="locations" />
</template>

<script>
import BookingCreate from '../../booking/Create.vue';

export default {
    components: {
        BookingCreate
    },
    mounted() {
        this.updateLocations()
    },
    data () {
        return {
            locations: []
        }
    },
    methods:{
        async updateLocations () {
            this.locations = await this.API.getUserLocations()
        }
    }
}
</script>

<style>
</style>