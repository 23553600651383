<template>
    <div
        class="bookingtable-wrapper"
        v-if="locations.length > 0 && experiences.length > 0"
    >
        <booking-filters
            :locations="locations"
            :experiences="experiences"
            :playerSum="totalPlayers"
            :averageRating="averageRating"
            :averageLocationRating="averageLocationRating"
            :averagePlayingTime="averagePlayingTime"
            @change="updateFilter"
        />
        <div v-if="!loading" class="bookingtable-container">
            <div class="table-tabs">
                <button
                    :class="`tab next-bookings ${
                        selectedTab === 1 ? 'selected' : ''
                    }`"
                    @click="() => selectTable(1)"
                >
                    Upcoming Bookings ({{ nextBookings.length }})
                </button>
                <button
                    :class="`tab past-bookings ${
                        selectedTab === 2 ? 'selected' : ''
                    }`"
                    @click="() => selectTable(2)"
                >
                    Past Bookings ({{ lastBookings.length }})
                </button>
            </div>
            <div class="tables">
                <booking-table
                    :class="`booking-table ${
                        selectedTab === 1 ? 'selected' : ''
                    }`"
                    :bookings="nextBookings"
                />
                <booking-table
                    :class="`booking-table ${
                        selectedTab === 2 ? 'selected' : ''
                    }`"
                    :bookings="lastBookings"
                />
            </div>
        </div>
        <spinner :loading="loading" text="Loading bookings..."></spinner>
    </div>
</template>

<script>
import dayjs from "dayjs";
import BookingTable from "./Table.vue";
import BookingFilters from "./Filters.vue";
import Spinner from "../shared/Spinner.vue";

export default {
    props: {
        locations: {
            type: Array,
            default: () => [],
        },
        experiences: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        BookingTable,
        BookingFilters,
        Spinner,
    },

    data() {
        return {
            nextBookings: [],
            lastBookings: [],
            filters: null,
            selectedTab: 1,
            loading: false,
        };
    },
    computed: {
        averageRating() {
            const {total, players } = this.lastBookings.reduce((acc, booking) => {
                if (booking.rating) {
                    return {
                        total: acc.total + booking.rating * booking.player_count,
                        players: acc.players + booking.player_count,
                    }
                }
                return acc;
            }, {total: 0, players: 0});
            if (players > 0 )  {
                return total/players;
            }
            return null;
        },
        averageLocationRating() {
            const {total, players } = this.lastBookings.reduce((acc, booking) => {
                if (booking.rating_location) {
                    return {
                        total: acc.total + booking.rating_location * booking.player_count,
                        players: acc.players + booking.player_count,
                    }
                }
                return acc;
            }, {total: 0, players: 0});
            if (players > 0 )  {
                return total/players;
            }
            return null;
        },
        averagePlayingTime() {
            const {total, players } = this.lastBookings.reduce((acc, booking) => {
                if (booking.minutes_played) {
                    return {
                        total: acc.total + booking.minutes_played * booking.player_count,
                        players: acc.players + booking.player_count,
                    }
                }
                return acc;
            }, {total: 0, players: 0});
            if (players > 0 )  {
                return total/players;
            }
            return null;
        },        
        totalPlayers() {
            let players = this.lastBookings.reduce((acc, booking) => acc + booking.player_count, 0);
            if (this.selectedTab === 1) {
                players += this.nextBookings.reduce((acc, booking) => acc + booking.player_count, 0);
            }
            return players;
        },
    },
    watch: {
        filters() {
            this.filterBookings();
        },

        async $route(to) {
            to.name == "admin-bookings" ||
            to.name == "operator-bookings" ||
            to.name == "bookings"
                ? this.filterBookings()
                : null;
        },
    },

    methods: {
        updateFilter(f) {
            this.filters = f;
        },

        selectTable(tabNum) {
            this.selectedTab = tabNum;
        },

        async filterBookings() {
            if (!this.filters) {
                return;
            }
            const startDate = this.filters.startDate || new Date(0);
            const endDate = this.filters.endDate || new Date(2999, 0, 1);

            const filters = {
                include_deleted: this.filters.options.includes("Only deleted") || this.filters.options.includes("Include deleted")|| null, // 2024-07-15 replace false by null to make the api work (controllers/booking.js#16)
                date_lte: dayjs(endDate).endOf("day").toISOString(),
                date_gt: dayjs(startDate).startOf("day").toISOString(),
                _limit: this.filters.limit,
                _sort: "date:desc",
                demo: false,
            };
            if (this.filters.options.includes("Demo")) {
                filters.demo = true;
            }
            if (this.filters.options.includes("Consent To Share")) {
                filters.consent_to_share = true;
            }
            if (this.filters.xcube?.id !== "all") {
                filters["xcube.id"] = this.filters.xcube.id;
            }
            if (this.filters.location?.id !== "all") {
                filters["xcube.location.id"] = this.filters.location.id;
            }

            if (this.filters.experience?.id !== "all") {
                filters["experience.id"] = this.filters.experience.id;
            }

            try {
                this.loading = true;
                const bookings = (await this.API.getBookingTable(filters))
                    .map((booking) => {
                        const options = [];
                        if (booking.deleted_at != null) {
                            options.push("Deleted");
                        }
                        if (booking.refund_requested) {
                            options.push("Refund");
                        }
                        if (booking.voucher_requested) {
                            options.push("Voucher");
                        }
                        if (booking.demo) {
                            options.push("Demo");
                        }
                        if (booking.consent_to_share) {
                            options.push("Consent To Share");
                        }
                        return { ...booking, options };
                    })
                    .filter((booking) => {
                        const options = this.filters.options.filter((o) => !o.includes("deleted"));
                        if (this.filters.options.includes("Only deleted")) {
                            options.push("Deleted");
                        }
                        return options.every((option) =>
                            booking.options.includes(option)
                        );
                    });

                this.lastBookings = bookings.filter(
                    (booking) => new Date(booking.date) < new Date()
                );
                this.nextBookings = bookings
                    .filter((booking) => new Date(booking.date) > new Date())
                    .reverse();
                this.loading = false;
            } catch (e) {
                this.loading = false;
                alert(e.message);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped lang="scss">
.bookingtable-wrapper {
    position: absolute;
    left: 2vw;
    top: 2vh;
    width: calc(100% - 4vw);
    height: calc(100% - 4vh);
}

.bookingtable-container {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
}

.table-tabs {
    display: flex;
    justify-content: space-between;

    .tab {
        width: 50%;

        background-color: #000000b5;
        color: #e8de16;

        border: 1px solid #e8de16;
        border-radius: 10px;

        padding: 5px;

        &.selected {
            background-color: #e8de16;
            color: black;
            font-weight: bold;
        }

        &:nth-child(1) {
            border-radius: 10px 0 0 0;
        }

        &:nth-child(2) {
            border-radius: 0 10px 0 0;
        }
    }
}

.tables {
    height: 100%;
    display: flex;
    flex-direction: column;
    font-size: 1rem;

    .booking-table {
        // flex: 1 1 320px;

        border: 1px solid #e8de16;

        &:not(.selected) {
            display: none;
        }

        &:nth-child(1) {
            border-radius: 0 0 0 10px;
        }

        &:nth-child(2) {
            border-radius: 0 0 10px 0;
        }
    }
}

.page {
    width: 100%;
}

.spinner {
    padding: 4rem;
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 320px) {
    .table-tabs {
        font-size: 4vw;
    }
}

@media only screen and (max-width: 768px),
    (min-device-width: 1024px) and (max-device-width: 1440px) {
    .table-tabs {
        &.active {
            display: flex;

            .selected {
                background-color: #ffffff30;
            }
        }

        &.inactive {
            display: none;
        }
    }

    .tables [data-tab] {
        display: none;

        &.selected {
            display: block;
        }
    }
}

@media (min-device-width: 1440px) and (max-device-width: 1872px) {
    .tables {
        font-size: 0.85vw;
    }
}
</style>