import { Model } from "@vuex-orm/core";
import Client from "./client";

export default class Team extends Model {
  static entity = "teams";
  static eagerLoad = ['clients'];

  static fields () {
    return {
      id: this.attr(),      
      name: this.string(),
      score: this.number(),
      clients: this.hasMany(Client, 'id'),
      owner: this.hasOne(Client, 'id'),
      token: this.string(),
      register_token: this.string(),
      created_at: this.string(),
      updated_at: this.string(), 
    };
  }
}
