import { DefaultAdapter, ConnectionMode, ArgumentMode } from "@vuex-orm/plugin-graphql"

export default class CustomAdapter extends DefaultAdapter {
    constructor() {
        super()
    }

    getConnectionMode() {
        return ConnectionMode.PLAIN
    }

    getArgumentMode() {
        return ArgumentMode.TYPE
    }
    
    getInputTypeName(model, action) {
        const result = super.getInputTypeName(model, action)
        return result;
    }
}
