<template>
    <div v-if="summary && texts">
        <h4>{{ texts.title_text }}</h4>

        <div class="photos">
            <div v-for="photo in summary.photos" :key="photo.url">
                <div class="media-wrapper">
                    <img :class="`media`" :src="toAbsoluteURL(photo.url)" />
                    <button
                        @click="() => downloadMedia(photo.url)"
                    >
                        <font-awesome-icon icon="fa-solid fa-download" />
                        Download
                    </button>
                    <button
                        v-if="shareSupported"
                        @click="() => shareMedia(photo.url)"
                    >
                        <font-awesome-icon icon="fa-solid fa-share" />
                        {{ texts.share_button_text }}
                    </button>
                </div>
            </div>
        </div>

        <div v-if="summary.reel_video">
            <div class="media-wrapper">
                <video controls class="media">
                    <source
                        :src="toAbsoluteURL(summary.reel_video.url)"
                        type="video/mp4"
                    />
                </video>
                <button
                    class="video-button"
                    @click="
                        () =>
                            downloadMedia(summary.reel_video.url)
                    "
                >
                    <font-awesome-icon icon="fa-solid fa-download" /> Download
                </button>
                <button
                    class="video-button"
                    v-if="shareSupported"
                    @click="
                        () => shareMedia(summary.reel_video.url)
                    "
                >
                    <font-awesome-icon icon="fa-solid fa-share" />
                    {{ texts.share_button_text }}
                </button>
            </div>
        </div>
        <div
            v-else-if="
                summary.experience.reels_enabled &&
                summary.end_of_game_mail_sent &&
                !summary.reel_preview &&
                !summary.reel_video
            "
            class="media reel-generating"
        >
            <div class="loader">Loading...</div>
        </div>
        <div
            v-else-if="summary.experience.reels_enabled"
            class="media reel-generating"
        >
            <h4>{{ texts.reel_generate_info }}</h4>
            <font-awesome-icon
                icon="fa-solid fa-video"
                style="color: #ff0; height: 3em"
            />
        </div>

        <div class="social-media">
            <a href="https://www.facebook.com/xcubenl" target="_blank"
                ><font-awesome-icon icon="fa-brands fa-facebook-square fa-10x"
            /></a>
            <a href="https://www.instagram.com/xcubenl/" target="_blank"
                ><font-awesome-icon icon="fa-brands fa-instagram-square fa-10x"
            /></a>
            <a href="https://www.linkedin.com/company/xcorpbv/" target="_blank"
                ><font-awesome-icon icon="fa-brands fa-linkedin fa-10x"
            /></a>
        </div>
    </div>
</template>

<script>
import { downloadFile, toAbsoluteURL } from "./../../helpers/download";

export default {
    components: {},

    data() {
        return {
            photos: [],
            summary: null,
            texts: null,
            shareSupported: navigator.share !== undefined,
        };
    },
    async mounted() {
        this.summary = await this.fetchSummary();
        if (this.summary.statusCode === 500) {
            this.summary = null;
            this.$message.error({
                message: "Something went wrong. Please try again later",
            });
        } else if (!this.texts) {
            this.texts = await this.getTexts();
        }
    },

    methods: {
        toAbsoluteURL,
        downloadMedia(url) {
            downloadFile(toAbsoluteURL(url));
        },
        async fetchSummary() {
            return this.API.getSummary(this.$route.params.token);
        },
        async getTexts() {
            const contentBlocks = await this.API.getContentBlocks(
                "hub-summary-page"
            );
            if (!contentBlocks || contentBlocks.length == 0) {
                return {};
            }
            const contentBlock = contentBlocks[0];
            const content = contentBlock.content.find(
                (block) => block.language === this.summary.language
            );
            return content || {};
        },
        async shareMedia(url) {
            const blob = await fetch(toAbsoluteURL(url)).then((res) => res.blob());
            const isVideo = blob.type.startsWith("video");
            const data = isVideo
                ? {
                      title: "X-Cube experience",
                      text: this.texts.share_caption_text,
                      files: [
                          new File([blob], "my-xcube-experience.mp4", {
                              type: blob.type,
                          }),
                      ],
                  }
                : {
                      title: "X-Cube experience",
                      text: this.texts.share_caption_text,
                      files: [
                          new File([blob], "my-xcube-photo.jpg", {
                              type: blob.type,
                          }),
                      ],
                  };

            if (navigator.canShare(data)) {
                navigator.share(data);
            }
        },
    },
};
</script>

<style scoped lang="scss">
h4 {
    color: #ff0;
}

.media {
    border: 3px solid #ff0;
    border-radius: 20px;
    max-width: 20em;
    @media (min-width: 600px) {
        max-width: 28em;
    }
}

.social-media {
    background-color: white;
    max-width: 80%;
    display: contents;
    .svg-inline--fa {
        height: 2em;
        margin: 5px;
        color: #ff0;
    }
}

.media-wrapper {
    margin: auto;
    max-width: 20em;
    @media (min-width: 600px) {
        max-width: 28em;
    }
    button {
        position: relative;
        bottom: 35px;
        cursor: pointer;
        border: 0;
        background: #ff0;
        padding: 2px 10px 2px;
        font-weight: bold;
        border-radius: 4px;
        margin: 2px;
        &.video-button {
            bottom: 5px;
        }
    }
}

.photos {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: space-evenly;
    @media (max-width: 600px) {
        justify-content: center;
    }
}

.reel-generating {
    height: 20em;
    background: #0005;
    margin: auto;
    h4 {
        max-width: 90%;
        margin: 2em auto;
    }
}

.loader,
.loader:before,
.loader:after {
    background: #ff0;
    animation: load 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
}
.loader {
    color: #ff0;
    text-indent: -9999em;
    margin: 100px auto;
    position: relative;
    font-size: 15px;
    transform: translateZ(0);
    animation-delay: -0.16s;
}
.loader:before,
.loader:after {
    position: absolute;
    top: 0;
    content: "";
}
.loader:before {
    left: -1.5em;
    animation-delay: -0.32s;
}
.loader:after {
    left: 1.5em;
}
@keyframes load {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}
</style>