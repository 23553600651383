<template>
  <div>
      <template v-if="user.client">
        <h2>Your X-Cube account</h2>
        <hr /> 
        <div class="account">
          <div slot="header" class="clearfix">
            <span><div class="block"><el-avatar style="float:left" :size="50" :src="circleUrl"></el-avatar></div></span>
            <el-badge style="margin-top: 15px; padding: 3px 0">{{user.client.first_name}} {{user.client.last_name}} </el-badge>
          </div>
          <div class="text item">
            {{'First name: ' +  user.client.first_name}}
          </div>
          <div class="text item">
            {{'Last name: ' +  user.client.last_name}}
          </div>
          <div class="text item">
            {{'Phone number: ' +  user.client.phone_number}}
          </div>
          <div class="text item">
            {{'Email: ' +  user.client.email}}
          </div>
        </div>
        </template>
  </div>
</template>

<script>

export default {
  data () {
    return {
      circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      sizeList: ["large"],
      user: {},
    }
  },

  created() {
    this.fetchData();
  },

  watch: {
    '$route': 'fetchData'
  },

  methods: {
    async fetchData () {
      this.user = await this.API.user;
    }
  }
}
</script>

<style scoped>
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
  float:left;
  width: 100%;
  text-align: left;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  margin-bottom: 10px;
}
.clearfix:after {
  clear: both
}

.box-card {
  margin-top: 20px;
  width: 480px;
}
.avatar {
  margin-top: 10px;
}

.account {
  margin-left: 10px;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>