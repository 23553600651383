<template>
    <div v-if="loading" class="loading-container">
        <div class="loading-spinner"></div>
        <p>{{ text }}</p>
    </div>
</template>
  
  <script>
export default {
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        text: {
            type: String,
            default: "Loading ...",
        },
    },
};
</script>
  
  <style scoped lang="scss">
@import "../../colors";

.loading-container {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.7);
    justify-content: center;
}

.loading-spinner {
    width: 80px;
    height: 80px;
    border: 10px solid $border-color-base;
    border-top: 10px solid $color-primary;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>