<template>
    <div class="move-booking">
        <spinner :loading="loading" text="Moving booking..."></spinner>
        <hub-form v-slot:formcontent v-if="booking" ref="form" :onSubmit="moveBooking"
            class="width-90-move center-content">
            <el-form label-width="160px">
                <template>
                    <div class="flex-container flex-center title-container">
                        <div>
                            <h2>Move Booking</h2>
                        </div>
                    </div>

                    <div class="form-wrapper">
                        <span>
                            <div class="info-wrapper">
                                <span class="info-label">Booking Number: </span>
                                <span class="info-value">{{
                                    booking.code
                                    }}</span>
                            </div>
                            <div class="info-wrapper">
                                <span class="info-label">Email: </span>
                                <span class="info-value">{{
                                    booking.client.email
                                    }}</span>
                            </div>
                            <div class="info-wrapper">
                                <span class="info-label">Phone number: </span>
                                <span class="info-value">{{
                                    booking.client.phone_number
                                    }}</span>
                            </div>
                            <div class="info-wrapper">
                                <span class="info-label">Location: </span>
                                <span class="info-value">{{
                                    booking.xcube.location.name
                                    }}</span>
                            </div>

                            <div class="info-wrapper">
                                <span class="info-label">X-Cube Name: </span>
                                <span class="info-value">{{
                                    booking.xcube.name
                                    }}</span>
                            </div>
                            <div class="info-wrapper">
                                <span class="info-label">Team name</span>
                                <span class="info-value">{{
                                    booking.team.name
                                    }}</span>
                            </div>
                            <div class="info-wrapper">
                                <span class="info-label">Player Count</span>
                                <span class="info-value">{{
                                    booking.player_count
                                    }}</span>
                            </div>
                            <div class="info-wrapper">
                                <span class="info-label">Language</span>
                                <span class="info-value">{{
                                    booking.language
                                    }}</span>
                            </div>
                            <div class="info-wrapper">
                                <span class="info-label">Experience</span>
                                <span class="info-value">{{
                                    booking.experience.name
                                    }}</span>
                            </div>

                            <el-form-item label="New Event Date">
                                <div class="input-with-text">
                                    <date-picker v-model="form.event_date" :confirm="true" type="date"
                                        format="DD-MM-YYYY" placeholder="Select date" required />
                                </div>
                            </el-form-item>
                            <el-form-item label="New Event Time">
                                <div class="input-with-text">
                                    <date-picker v-model="form.event_time" :confirm="true" type="time" format="HH:mm"
                                        placeholder="Select time" required />
                                </div>
                                <span class="text-next-to-input">Timezone: {{ getTimezoneName() }}</span>
                            </el-form-item>

                            <el-form-item label="">
                                <el-button @click="back">
                                    <font-awesome-icon icon="fa-solid fa-arrow-left" />
                                </el-button>
                                <el-button @click="moveBooking">Move</el-button>
                            </el-form-item>
                        </span>
                    </div>
                </template>
            </el-form>
        </hub-form>
    </div>
</template>

<script>
import * as Sentry from "@sentry/vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import dayjs from "dayjs";

import HubForm from "../shared/Form.vue";
import Format from "./../shared/Format.vue";
import ShowBooking from "../booking/Show.vue";
import Spinner from "../shared/Spinner.vue";
import ConflictError from "../../api/api";

export default {
    components: {
        ShowBooking,
        HubForm,
        DatePicker,
        Format,
        Spinner,
    },
    data() {
        return {
            loading: false,
            movedBooking: null,
            form: {
                event_date: new Date(),
                event_time: new Date(),
            },
            booking: null,
        };
    },
    async mounted() {
        this.booking = await this.API.fetchBookingData(this.$route.params.id);
        this.form.event_date = new Date(this.booking.date);
        this.form.event_time = new Date(this.booking.date);
    },
    methods: {
        getTimezoneName() {
            // Get the current timezone name
            const timezoneName =
                Intl.DateTimeFormat().resolvedOptions().timeZone;
            return timezoneName;
        },
        back() {
            this.$router.go(-1);
        },

        async moveBooking() {
            const time = dayjs(this.form.event_time);
            const code = `HUB-${new Date().getTime()}`;
            console.log("FORM", this.form.event_date);
            const eventDayjs = dayjs(this.form.event_date)
                .hour(time.hour())
                .minute(time.minute());
            if (eventDayjs.isBefore(dayjs())) {
                this.$message.error({
                    message: "Date/time should be in the future",
                });
                return;
            }
            const event_datetime = eventDayjs.toDate();

            const data = {
                code,
                event_datetime,
                booking_id: code,
                language: this.booking.language,
                name: `${this.booking.client.first_name} ${this.booking.client.last_name}`,
                email: this.booking.client.email,
                team_1_size: this.booking.player_count,
                team_1_name: this.booking.team.name,
                booking_datetime: new Date(),
                experience_id: Number.parseInt(this.booking.experience.id),
                xcube_id: Number.parseInt(this.booking.xcube.id),
                experienceName: this.booking.experience.name,
            };
            try {
                this.loading = true;
                const newBooking = await this.API.moveBooking(
                    this.booking,
                    data
                );
                this.loading = false;
                this.$message.success({ message: "Booking has been moved" });
                this.$router.push({
                    name: "view-booking",
                    params: { id: newBooking.id },
                });
            } catch (e) {
                this.loading = false;
                if (e.message === "conflict") {
                    this.$message.error({
                        message: `There is already another booking on this time`,
                    });
                } else {
                    this.$message.error({
                        message: `Error from server : ${e.message}`,
                    });
                    throw e;
                }
            }
        },

        /**
         * Method that hides the date picker table so that it doesn't mess up the time picker table.
         * Sets the attribute hidden once this method has been triggered.
         * There is no need to remove the hidden attribute because when a new date picker is opened it's a completely new render.
         * @returns {void}
         */
        toggleDatePickerTable() {
            let dateTable = document.getElementsByClassName("mx-table-date")[0];
            dateTable.classList.add("mx-hideElement");
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../colors.scss";

.form-container {
    display: flex;
    align-items: center;
    flex-direction: column;

    form.el-form {
        width: 100%;
    }
}

.info-wrapper {
    display: flex;
    padding-bottom: 12px;

    .info-label {
        width: 148px;
        text-align: right;
        padding-right: 12px;
    }

    .info-value {
        text-align: left;
    }
}

@media screen and (max-width: 800px) {
    .info-wrapper {
        flex-direction: column;
        align-items: center;

        .info-label {
            width: unset;
        }
    }
}
</style>