<template>
    <div class="login">
        <el-card>
            <img class="logo" src="/logo.png" />

            <h2>X-CUBE</h2>
            <h5>LOGIN</h5>

            <el-form
                class="login-form"
                :model="model"
                :rules="rules"
                ref="form"
                @submit.native.prevent="login"
            >
                <el-form-item prop="email">
                    <el-input
                        v-model="model.email"
                        placeholder="E-mail"
                        prefix-icon="fas fa-user"
                    ></el-input>
                </el-form-item>

                <el-form-item prop="password">
                    <el-input
                        v-model="model.password"
                        placeholder="Password"
                        type="password"
                        prefix-icon="fas fa-lock"
                    />
                </el-form-item>

                <el-form-item>
                    <el-button
                        :loading="loading"
                        :disabled="
                            model.email.length === 0 ||
                            model.password.length === 0
                        "
                        class="login-button"
                        type="primary"
                        native-type="submit"
                        block
                    >
                        Login
                    </el-button>
                </el-form-item>

                <router-link to="/forgotpassword">
                    Forgot password?
                </router-link>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import { isManagement, isOperator } from "../../helpers/auth";

export default {
    data() {
        return {
            model: {
                email: "",
                password: "",
            },
            loading: false,
            rules: {
                email: [
                    {
                        required: true,
                        message: "Vul je e-mailadres in",
                        trigger: "blur",
                    },
                ],
                password: [
                    {
                        required: true,
                        message: "Vul je wachtwoord in",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    methods: {
        async login() {
            const valid = await this.$refs.form.validate();
            if (!valid) {
                return;
            }

            try {
                this.loading = true;
                await this.API.login({
                    identifier: this.model.email,
                    password: this.model.password,
                });
                this.$message.success({ message: "Login successful" });

                if (isManagement(this?.API?.user)) {
                    this.$router.push("admin/bookings");
                } else if (isOperator(this?.API?.user)) {
                    this.$router.push("operator/bookings");
                } else {
                    this.$router.push("bookings");
                }
            } catch (error) {
                this.$message.error({ message: "Login failed, please check your username/password" });
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped>
.logo {
    height: 64px;
}
.login {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Titillium Web", Helvetica, Arial, Lucida, sans-serif;
}

.login-button {
    width: 100%;
    margin-top: 40px;
    font-family: "Titillium Web", Helvetica, Arial, Lucida, sans-serif;
}
.login-form {
    width: 290px;
}
.forgot-password {
    margin-top: 10px;
}
</style>
<style lang="scss">
$teal: rgb(0, 124, 137);
.el-button--primary {
    background: $teal;
    border-color: $teal;

    &:hover,
    &.active,
    &:focus {
        background: lighten($teal, 7);
        border-color: lighten($teal, 7);
    }
}
.login .el-input__inner:hover {
    border-color: $teal;
}
.login .el-input__prefix {
    background: rgb(238, 237, 234);
    left: 0;
    height: calc(100% - 2px);
    left: 1px;
    top: 1px;
    border-radius: 3px;
    .el-input__icon {
        width: 30px;
    }
}
.login .el-input input {
    padding-left: 35px;
    font-family: "Titillium Web", Helvetica, Arial, Lucida, sans-serif;
}
.login .el-card {
    padding-top: 0;
    padding-bottom: 30px;
}
h2 {
    // font-family: "Open Sans";
    letter-spacing: 1px;
    font-family: "Bungee", display;
    padding-bottom: 20px;
}
a {
    color: $teal;
    text-decoration: none;
    &:hover,
    &:active,
    &:focus {
        color: lighten($teal, 7);
    }
}
.login .el-card {
    width: 340px;
    display: flex;
    justify-content: center;
}
</style>
