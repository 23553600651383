import { Model } from "@vuex-orm/core";

export default class Experience extends Model {
  static entity = "experiences";

  static fields () {
    return {
      id: this.attr(null),
      name: this.string(""),
      number_of_players: this.number(1),
      duration: this.string(""),
      theme: this.string(""),
      created_at: this.string(),
      updated_at: this.string(), 
    };
  }
}

