<template>
  <span v-if="datetime">{{formattedDateTime}}</span>
  <span v-else-if="date">{{formattedDate}}</span>
  <span v-else-if="time">{{formattedTime}}</span>
</template>

<script>
export default {
    props: {
        datetime: String,
        date: String,
        time: String,
    },
    computed: {
        formattedDateTime() {
            return Intl.DateTimeFormat('nl-NL',{dateStyle: 'short', timeStyle: 'short'}).format(new Date(this.datetime))
        },
        formattedDate() {
            return Intl.DateTimeFormat('nl-NL',{year: 'numeric', month: 'long', day: 'numeric'}).format(new Date(this.date))
        },
        formattedTime() {
            return Intl.DateTimeFormat('nl-NL',{timeStyle: 'short'}).format(new Date(this.time))
        }
    }
}
</script>

<style>

</style>