<template>
<div style="width: 100%; height: 100%;">
  <template v-if="isLoading">
    
  </template>
  <template v-else>
    <el-col :span="24">
      <div class="wrapper">
        <div class="team-name"> 
          <template>

            <input type="text"
              v-if="edit"
              :value="team.name"
              @blur="team.name = $event.target.value; edit = false; $emit('input', team.name); saveTeamName($event.target.value);"
              @keyup.enter="team.name = $event.target.value; edit = false; $emit('input', team.name); saveTeamName($event.target.value);"
             />
            <h2 v-else @click="edit = true;">{{this.team.name}}</h2>
            <small><br />Click on the teamname to change it</small>
            <hr />
            
          </template>
        </div> 
        <el-table
          :data="team.clients"
          style="width: 100%">
          <el-table-column
            prop="first_name"
            label="Leden">
          </el-table-column>
          <el-table-column
            prop="last_name">
          </el-table-column>
        </el-table>
      </div>
      <div>
      <div style="margin-top: 20px; height: 200px;">
      <el-button @click="show = !show">Nodig vrienden uit</el-button>
        <el-collapse-transition>
          <div style="margin-top:20px; background-color:black;" v-show="show">
            <p>Kopiëer deze link en stuur deze naar uw vrienden </p>
              <p>{{`${hubUrl}/#/invite/${team.token}`}}</p>
            <template v-if="secureContext">
              <p><button @click="copyUrl()">{{buttonText}}</button></p>
            </template>
            </div>
          </el-collapse-transition>
        </div>
      </div>
    </el-col>
  </template>
  </div>
</template>

<script>
  export default {
    async created () {
      this.fetchData();
    },

    async mounted() {
      await this.getTeam();
    },

    computed: {
      isOwner: function () {
        if(this.team){
          return this.team.owner == this.user;
        } else {
          return false
        }
      }
    },

    watch: {
      '$route': 'fetchData',
    },

    data() {
      return {
        secureContext: window.isSecureContext,
        user: null,
        buttonText: 'Copy',
        edit: false,
        team: {
          name: '',
        },
        isLoading: true,
        show: false,  
        name: '',
        email: '',
        customMessage: '',
        teamName: '',
      }
    },

    methods: {
      copyUrl(){
        const url = `${this.hubUrl}/#/invite/${this.team.token}`;
        if(window.isSecureContext){
          navigator.clipboard.writeText(url);
          this.buttonText = 'Copied!';
        }
        setTimeout(() => {
          this.buttonText = 'Copy'
        }, 1000);
      },

      async getTeam(){
         const query = `
          query Teams {
            teams {
              id
              name
              token
              clients {
                id
                username
                first_name
                insertion
                last_name
              }
              owner {
                id
              }
            }
          }`;

      const result = await this.$store.dispatch('entities/simpleQuery', 
        { 
          query, 
          bypassCache: true 
        });
        this.team = result.teams[0];
        
      },

      async saveTeamName(name){
        if(name){
          this.team.name = name;
          this.teamName = name;
        } else {
          this.team.name = this.teamName;
        }

      const query = `
      mutation UpdateTeam($teamID: ID!, $name: String ) {
        updateTeam(input: {
              where: { id: $teamID }
              data: { name: $name }
            }) {
              team {
                id
                name
              
              }
            }
      }`;

      this.$store.dispatch('entities/simpleMutation', 
        { 
          query, 
          variables: 
            { 
              "teamID": this.team.id,
              "name": this.teamName 
            }, 
          bypassCache: true 
        });
      this.team.name = this.teamName;
    },

    async fetchData () {
      this.user = await this.API.user;
      this.isLoading = false;
    },
  }
}
</script>

<style scoped>

input {
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.wrapper {
  display: flex;
  flex-direction: column;
}

.invite-input {
  margin-top: 10px;
}
.transition-box {
  margin-bottom: 10px;
  width: 500px;
  height: 500px;
  border-radius: 4px;
  text-align: left;
  padding: 40px 20px;
  box-sizing: border-box;
  margin-right: 20px;
}
h3 {
  margin: 40px 0 0;
}
small {
  font-style: italic;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
