<template>
  <div>
    <h1>General Information</h1>
    <render-content-blocks :content-blocks="contentBlocks" />
  </div>
</template>

<script>
import api from "../../api/api";
import RenderContentBlocks from "../content-block/RenderContentBlocks.vue";
export default {
  components: {
    RenderContentBlocks,
  },
  async mounted() {
    this.contentBlocks = await api.getContentBlocks("operator-info");
  },
  data() {
    return {
      contentBlocks: [],
    };
  },
};
</script>

<style></style>
