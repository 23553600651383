<template>
    <div>
        <template v-if="isManagement">
            <h1>Tools</h1>
            <h3>Customer Data</h3>
            <el-button @click="downloadCSV">Download</el-button>
        </template>
        <template v-else>
            <h1>Unauthorized access</h1>
        </template>
    </div>
</template>


<script>
    import { isManagement } from "../../../helpers/auth";
import {saveTextAsFile} from "../../../helpers/saveTextasFile";
    
    export default {
        name:"Tools",
        methods:{
            /**
             * Method that runs a shell command that compiles an SQL query to a CSV File. 
             * @param {string} sqlFile
             * @returns void
             **/
            async downloadCSV(){
                let result = await this.API.getCustomersCSV();
                saveTextAsFile(result.csvData,"customer_data.csv","csv");
            },
        },
        computed:{
            userRoleName() {
                return (this.API && this.API.user && this.API.user.role && this.API.user.role.name) || "";
            },
            isManagement() {
                return isManagement(this?.API?.user);
            },
        },
    }
</script>