<template>
  <hub-form v-slot:formcontent ref="hubForm" :onSubmit="submit">
    <template v-if="!submitted">
      <input placeholder="email" type="email" v-model="member.email" required/>
      <input placeholder="First name" v-model="member.first" required/>
      <input placeholder="Last name" v-model="member.last" required/>
      <input placeholder="Team name" v-model="member.team" required/>
      <button type="submit">Confirm changes</button>  
    </template>
  </hub-form>
</template>

<script>
import HubForm from "../shared/Form.vue";

export default {

  components:{
    HubForm
  },
  
  data() {
    return {
      submitted: false,
      message: '',
      teamName: '',
      member: {
        email: '',
        first: '',
        last: '',
        team: '',
      }
    }
  },
  
  methods: {
    async submit(){
      try {
        if (this.member.first == '') throw new Error('First name invalid')
        if (this.member.last == '') throw new Error('Last name invalid')
        if (this.member.email == '') throw new Error('Email invalid')
        if (this.member.team == '') throw new Error('Team name invalid')

        await this.API.registerTeamOwner(this.$route.params.register_token, this.member);

        this.$refs.hubForm.success("Your team has been registered, please continue on the big screen.");
        this.submitted = true
      } catch(e) {
        this.$refs.hubForm.error(`Error: ${e.message}`)
      }
    },
  }
}
</script>

<style scoped>

</style>