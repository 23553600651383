import Account from "./../components/views/Account.vue";
import Applications from "./../components/views/admin/Applications.vue";
import Application from "./../components/views/admin/Application.vue";
import Bookings from "./../components/views/Bookings.vue";
import Confirm from "./../components/views/Confirm.vue";
import ForgotPassword from "./../components/views/ForgotPassword.vue";
import Home from "./../components/views/Home.vue";
import GeneralInformation from "../components/views/GeneralInformation.vue";
import SiteInformation from "../components/views/SiteInformation.vue";
import Invite from "./../components/views/Invite.vue";
import AdminBookings from "./../components/views/admin/Bookings.vue";
import AdminXCubes from "./../components/views/admin/XCubes.vue";
import Login from "./../components/views/Login.vue";
import AdminBookingsCreate from "./../components/views/admin/BookingsCreate.vue";
import OperatorBookings from "./../components/views/operator/Bookings.vue";
import OperatorBookingsCreate from "./../components/views/operator/BookingsCreate.vue";
import Photos from "./../components/views/Photos.vue";
import RegisterTeamOwner from "./../components/views/RegisterTeamOwner.vue";
import ResetPassword from "./../components/views/ResetPassword.vue";
import Team from "./../components/views/Team.vue";
import VueRouter from "vue-router";
import View from "../components/booking/View.vue";
import XCubeControl from "./../components/views/admin/XCubeControl.vue";
import Tools from "./../components/views/admin/Tools.vue";
import BookingsMove from "../components/views/MoveBooking.vue";

import { api } from "./../api";
import { isManagement, isOperator } from "../helpers/auth";

const routes = [
  {
    path: "/confirm/:token",
    component: Confirm,
    meta: { public: true },
  },
  {
    path: "/login",
    component: Login,
    meta: { public: true },
    name:"login",
  },
  {
    path: "/resetpassword/:token",
    component: ResetPassword,
    meta: { public: true },
    name: "ResetPassword",
  },
  {
    path: "/forgotpassword",
    component: ForgotPassword,
    meta: { public: true },
    name: "ForgotPassword",
  },
  {
    path: "/invite/:token",
    component: Invite,
    meta: { public: true },
  },
  {
    path: "/summary/:token",
    component: Photos,
    meta: { public: true },
  },
  {
    path: "/register/:register_token",
    component: RegisterTeamOwner,
    meta: { public: true },
  },
  {
    path: "/admin/bookings",
    component: AdminBookings,
    name: "admin-bookings",
    meta: { admin: true },
  },
  {
    path: "/admin/applications/",
    component: Applications,
    name: "applications",
    meta: { admin: true },
  },
  {
    path: "/admin/applications/:application_id",
    component: Application,
    name: "application",
    meta: { admin: true },
  },
  {
    path: "/admin/xcubes",
    component: AdminXCubes,
    name: "admin-xcubes",
    meta: { admin: true },
  },
  {
    path:"/admin/tools",
    component: Tools,
    name:"admin-tools",
    meta: {admin : true},
  },
  {
    path: "/admin/xcubecontrol",
    component: XCubeControl,
    name: "admin-xcube-control",
    meta: { admin: true },
  },
  {
    path: "/admin/bookings/create",
    component: AdminBookingsCreate,
    name: "admin-bookings-create",
    meta: { admin: true },
  },
  {
    path: "/bookings/move/:id",
    component: BookingsMove,
    name: "bookings-move",
    meta: { admin: true },
  },
  {
    path: "/operator/bookings",
    component: OperatorBookings,
    name: "operator-bookings",
  },
  {
    path: "/operator/bookings/create",
    component: OperatorBookingsCreate,
    name: "operator-bookings-create",
  },
  {
    path: "/information/general",
    component: GeneralInformation,
    name: "general-information",
  },
  {
    path: "/information/site",
    component: SiteInformation,
    name: "site-information",
  },
  {
    path: "/bookings",
    component: Bookings,
    name: "bookings",
  },
  {
    path: "/account",
    component: Account,
    name: "account",
  },
  {
    path: "/team",
    component: Team,
    name: "team",
  },
  {
    path: "/bookings",
    component: Home,
    name: "home",
  },
  {
    path: "/bookings/view/:id",
    component: View,
    name: "view-booking",
  },
];

const router = new VueRouter({ routes, mode: "history" });

router.beforeEach((to, from, next) => {
  if (to.fullPath.startsWith('/#')) {
    const path = to.fullPath.substring(2);
    next(path);
    return;
  }
  next();
});

router.beforeEach(async (to, from, next) => {
  let currentUser = api.user;
  if (!currentUser){
    try {
      currentUser = await api.fetchUserData();
    } catch (e) {
      currentUser = null;
    }
  }
  if (!currentUser) {
    if (to.meta.public) {
      next();
    } else {
      next({name: "login"});
    }
  } else {
    if (to.name === "login" || to.path === "/") {
      if (isManagement(api.user)) {
        next({ name: "admin-bookings" });
      } else if (isOperator(api.user)) {
        next({name : "operator-bookings"});
      } else {
        next({name: "bookings"});
      }
    } else if (to.meta.admin && !isManagement(api.user)) {
      next("/");
    } else {
      next();
    }
  }
});

export default router;
