<template>
  <div>
    <h2 class="page-header">Your X-Cube bookings</h2>

    <div class="booking-list col">
      <div :class="`booking-item`" v-for="booking in userBookings" :key="booking.id">
          <div class="booking-wrapper">
            <div class="booking-container">

                <div class="booking-header row">
                  <div class="datetime col">
                    <Format class="time" :time="booking.date" />
                    <Format class="date" :date="booking.date" />
                  </div>
                  <div class="more-info">
                    <button class="btn-more-info">More Info</button>
                  </div>
                </div>

                <div class="booking-image">
                  <div class="image-container" v-if="booking.photos && booking.photos.length">
                    <div class="slides">
                      <!-- Full-width images with number and caption text -->
                      <div :class="`slide slide-${photoIndex} fade ${photoIndex === 0 ? 'active' : ''}`" v-for="(photo, photoIndex) in booking.photos" :key="photoIndex">
                        <img :class="`image`" :src="toAbsoluteURL(booking.photos[photoIndex].url)" />
                        <button @click="() => downloadPhoto(booking.photos[photoIndex].url)">Download</button>
                      </div>

                      <a class="prev" @click="showSlides($event, -1)">&#10094;</a>
                      <a class="next" @click="showSlides($event, 1)">&#10095;</a>
                    </div>
                  </div>
                  <div v-else>
                    <div :class="`image-container experience-${booking.experience.id}`"></div>
                  </div>
                </div>

                <div class="booking-info col">
                  <div class="experience row">
                    <span>{{booking.experience?.name}}</span>
                  </div>
                  <div class="location-container row">
                    <span class="location-icon"></span>
                    <div class="location-wrapper col">
                      <div class="location row">
                        <span>{{booking.xcube.location?.name}}</span>
                      </div>
                      <div class="address row">
                        <span>{{booking.xcube.location?.address}} {{booking.xcube.location?.zip_code}}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="booking.score != 0" class="booking-doorcode row">
                  <span class="lock-icon hidden"></span>
                  <span class="doorcode-text">Score:</span>
                  <span class="doorcode">{{booking.score}}</span>
                </div>

                <div v-else-if="booking.door_code" class="booking-doorcode row">
                  <span class="lock-icon"></span>
                  <span class="doorcode-text">Doorcode:</span>
                  <span class="doorcode">{{booking.door_code}}</span>
                </div>
            </div>
          </div>
      </div>
    </div>

    <transition v-if="false" name="modal-fade">
      <div class="modal-backdrop">
        <div class="modal"
          role="dialog"
          aria-labelledby="modalTitle"
          aria-describedby="modalDescription"
        >
          <header
            class="modal-header"
            id="modalTitle"
          >
            <button
              type="button"
              class="btn-close"
              @click="closeModal"
              aria-label="Close modal"
            >
              x
            </button>
          </header>

          <section
            class="modal-body"
            id="modalDescription"
          >
            <slot name="body">
              Photos
            </slot>
          </section>
        </div>
      </div>
    </transition>

  </div>
</template>

<script>
  import Booking from './../../api/models/booking'
  import Format from './../shared/Format.vue'

  import {downloadFile, toAbsoluteURL} from './../../helpers/download'

  export default {
    components: {
      Format
    },

    created () {
      //this.fetchData();
    },
    
    async mounted() {
      this.userBookings = await this.API.getUserBookings();
    },

    data() {
      return {
        isModalVisible: false,
        userBookings: undefined,
      }
    },

    watch: {
      '$route': 'fetchData'
    },

    methods: {
      toAbsoluteURL,
      downloadPhoto (url) {
        downloadFile(toAbsoluteURL(url))
      },

      async fetchData () {
        await Booking.fetch();

      },
      showModal() {
        this.isModalVisible = true;
      },
      closeModal() {
        this.isModalVisible = false;
      },
      showSlides(event, increment){

        const slidesElement = event.target.closest(".slides")
        const slides = slidesElement.querySelectorAll(".slide")
        const active = slidesElement.querySelectorAll(".slide.active")[0]

        const activeIdx = Object.values(slides).indexOf(active)
        
        let n = activeIdx || 0;

        n += (increment % slides.length)

        //keeps n within bounds. disregards increment and assumes it is 1
        if(n > slides.length-1){n = 0}
        else if(n < 0){ n = slides.length-1}

        active.classList.contains("active") ? active.classList.remove("active") : '';

        const nextActive = slidesElement.querySelector(`.slide-${n}`)

        nextActive.classList.add("active")
      }
    }
  }
</script>

<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.flex{
  display:flex;
}
.row{
  display:flex;
  flex-direction:row;
}
.col{
  display:flex;
  flex-direction: column;
}

.page-header{
  font-size:3vmin;
}

.booking-wrapper{
    background-color: #ffffff1f;
    width: 80vmin;
    margin: 10px auto 10px auto;
    border-radius: 10px;
}

.booking-container{
  height: 100%;
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.booking-header{

  margin: 10px 0 5px 0;

  .datetime{

    .time{
      color:white;
      font-size: 7vmin;
      line-height: 1;
      margin-right: auto;
    }
    .date{
      color: yellow;
      font-size: 3vmin;
      margin-left: 3px;
    }

  }

  .more-info{
    display:none;
  }

}

.booking-image{

  margin: 5px 0 5px 0;

  .image-container{
    height: 35vmin;
    border: 2px solid yellow;
    border-radius: 10px;
    position: relative;
    background-size: 100% 140%;
    background-position: 0% 32%;

    background-image: url('../../image/spytraining-thumb.jpg'); //backup placeholder

    &.experience-id1{
      background-image: url('../../image/spytraining-thumb.jpg');
    }
    &.experience-id2{
      background-image: url('../../image/spytraining-kids-thumb.jpg');
    }
  }

}

.booking-info{
  margin: 5px 0 5px 0;
  color: white;

  .experience>span{
    font-size: 4vmin;
  }

  .location>span{
    font-size: 3vmin;
  }

  .address>span{
    font-weight: 300;
    font-size: 3vmin;
  }

  .location-icon{
    width: 5vmin;
    height: 5vmin;
    background-image: url('../../image/location.svg');
    background-size: 100% 100%;
    margin: auto 1% auto 1%;
  }
}

.booking-doorcode{
  margin: 5px 0 10px 0;
  background-color: #ffffff14;
  height: 7vmin;
  border-radius: 5px;
  
  .lock-icon{
    width: 3vmin;
    height: 3vmin;
    background-image: url('../../image/lock.svg');
    background-size: 100% 100%;
    margin: auto 0 auto 3%;

    &.hidden{
      visibility: hidden;
    }
  }
  .doorcode-text{
    color:white;
    text-transform: uppercase;
    font-size: 3vmin;
    margin: auto 0 auto 2%;
  }
  .doorcode{
    color: yellow;
    margin: auto 0 auto 5%;
    letter-spacing: 5px;
    font-size: 3vmin;
  }
  
}


.booking-list {
  display: grid;
}
.label {
  font-family: "Bungee";
  font-size: 15px;
}
.content {
  font-family: "Bungee";
  font-size: 25px;
  color: #ffff00;
  background-color: black;
  margin-bottom: 15px;
  line-height: 26px;
}

   /* Modal */
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    background: black;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    min-width: 20%;
    min-height: 20%
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    color: #ffff00;
    justify-content: space-between;
  }
  .modal-body {
    position: relative;
    padding: 20px 10px;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #ffff00;
    background: transparent;
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }
  /* Modal End */

  /* Slides */

  .slideshow-container {
    max-width: 1000px;
    position: relative;
    margin: auto;
  }

  .slides {
    width: 100%;
    height: 100%;

    .slide {
      width: 100%;
      height: 100%;

      display: none;

      &.active{
        display:block;
      }

      .image{
        width: 100%;
        height: 100%;
        border-radius: 8px;

        background-position: center;
        background-size: cover;
      }

      button {
        position: absolute;
        bottom: 10px;
        right: 10px;
        cursor: pointer;
        border: 0;
        background: white;
        padding: 4px 10px 2px;
        border-radius: 4px;
      }
    }

    a.prev{
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 60px;
      color: yellow;
      user-select: none;
    }
    a.next{
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 60px;
      color: yellow;
      user-select: none;
    }
  }

//   /* Fading animation */
// .fade {
//   -webkit-animation-name: fade;
//   -webkit-animation-duration: 1.5s;
//   animation-name: fade;
//   animation-duration: 1.5s;
// }

// @-webkit-keyframes fade {
//   from {opacity: .4}
//   to {opacity: 1}
// }

// @keyframes fade {
//   from {opacity: .4}
//   to {opacity: 1}
// }

  /* Slides End */
</style>
