<template>
    <booking-overview :locations="locations" :experiences="experiences" />
</template>

<script>
import BookingOverview from '../../booking/Overview.vue';

export default {
    name:"adminBookingOverviewComponent",
    components: {
        BookingOverview
    },
    mounted() {
        this.updateLocations()
        this.updateExperiences()
    },
    data () {
        return {
            locations: [],
            experiences: [],
        }
    },
    methods:{
        async updateLocations () {
            this.locations = await this.API.getUserLocations()
        },
        async updateExperiences () {
            this.experiences = await this.API.getExperiences()
        }
    }
}
</script>

<style>
</style>