import { gql } from "apollo-boost";

export default gql`
  query Application($id: ID!){
    application (id: $id) {
      id
      type
      experience {
        name
      }
      x_cube_applications {
        id
        status
        version
        x_cube {
          id
          name
          location {
            name
          }
        }
      }
    }
  }
`;
