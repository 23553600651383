import { gql } from "apollo-boost";

export default gql`
  query Booking($id: ID!) {
    booking(id: $id) {
      id
      code
      demo
      consent_to_share
      door_code
      deleted_at
      refund_requested
      voucher_requested
      xcube {
        id
        name
        location {
          time_zone
          name
        }
      }
      team {
        name
      }
      player_count
      language
      rating
      experience {
        id
        name
      }
      score
      date
      rating
      rating_location
      results
      minutes_played
      client {
        id
        first_name
        last_name
        email
        zip_code
        place_name
        country
        phone_number
      }
      photos {
        id
        url
        size
        previewUrl
      }
    }
  }
`;
