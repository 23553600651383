import { Model } from "@vuex-orm/core";
import Client from "./client";
import Xcube from "./xcube";
import Experience from "./experience";
import Team from "./team";
// import Image from "./image"


export default class Booking extends Model {
  static entity = "bookings";

  static fields() {
    return {
      id: this.attr(null),
      language: this.string(""),
      payed: this.boolean(false),
      payment_info: this.string(""),
      score: this.number(),
      note: this.string(""),
      date: this.string(),
      client: this.belongsTo(Client, 'client_id'),
      client_id: this.attr(),
      team: this.belongsTo(Team, 'team_id'),
      team_id: this.attr(),
      experience: this.belongsTo(Experience, 'experience_id'),
      experience_id: this.attr(),
      xcube: this.belongsTo(Xcube, 'xcube_id'),
      xcube_id: this.attr(),
      created_at: this.string(),
      updated_at: this.string(),
      door_code: this.string(),
      code: this.string(),
    };
  }
}
