import { gql } from "apollo-boost";

export default gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      email
      username
      confirmed
      client {
        id
        username
        first_name
        insertion
        last_name
        date_of_birth
        gender
        email
        email_verified
        address1
        address2
        zip_code
        place_name
        country
        phone_number
        registration_date
        points
        activation_token
        created_at
        updated_at
        teams {
          name
          score
          token
          register_token
          created_at
          updated_at
        }
      }
      role {
        id
        name
      }
      locations {
        id
        name
        xcubes {
          id
        }
      }
    }
  }
`;
