import { Model } from "@vuex-orm/core";
import Client from "./client";
import Booking from "./booking";
import Location from "./location";


export default class Xcube extends Model {
  static entity = "xcubes";

  static fields () {
    return {
      id: this.attr(null),      
      letter: this.string(),
      score: this.number(),
      //teammembers(...): [Teammember]
      clients: this.hasMany(Client, 'id'),
      bookings: this.hasMany(Booking, 'id'),
      location: this.belongsTo(Location, 'location_id'),
      location_id: this.attr(),
      created_at: this.string(),
      updated_at: this.string(), 
    };
  }
}
