<template>
    <div class="booking-show">
        <div v-if="booking">
            <strong>
                <label>Door code:</label><br />
                {{booking.door_code}}
            </strong>
            <br /><br />
            <label>Name:</label><br />
            {{booking.client.first_name}} {{booking.client.last_name}}
            <br /><br />
            <label>Email:</label><br />
            {{booking.client.email}}
            <br /><br />
            <label>Team:</label><br />
            {{booking.team.name}}
            <br /><br />
            <label>Language:</label><br />
            {{booking.language}}
            <br /><br />
            <label>Players:</label><br />
            {{booking.player_count}}
            <br /><br />
            <label>Date:</label><br />
            {{new Date(booking.date).toLocaleDateString()}}
            {{new Date(booking.date).toLocaleTimeString().substr(0,5)}}
            <br /><br />
            <label>X-Cube:</label><br />
            {{booking.xcube.letter}}
            <br /><br />
            <label>Experience:</label><br />
            {{booking.experience.name}}
            <br /><br />
        </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
        booking: {
            type: Object,
            default: () => ({}),
        }
    }
};
</script>

<style scoped lang="scss">
.booking-show {
    width: 480px;
    margin: 0 auto;
    strong {
        font-weight: 800;
    }
    label {
        opacity: 0.6;
    }
}
</style>