<template>
  <hub-form v-slot:formcontent ref="hubForm" :messages="messages" :onSubmit="savePassword">
    <h3>Enter a password to finish your registration and to get access to your bookings, scores en photos!</h3>
    <input placeholder="password" type="password" v-model="model.password" maxLength="32" minLength="5" required />
    <input placeholder="confirm password" type="password" v-model="model.confirmPassword" maxLength="32" minLength="5"
      required />
    <button @submit.prevent>Confirm password</button>
    <router-link to="/login">
      Back to login
    </router-link>
  </hub-form>
</template>

<script>
import HubForm, { MESSAGE_TYPES } from "../shared/Form.vue";

export default {
  components: {
    HubForm,
  },
  data() {
    const token = this.$route.params.token;
    return {
      token,
      messages: {
        "200-299": { text: "Successfully registered account", type: MESSAGE_TYPES.success },
        "404": { text: "Token not found, please contact support.", type: MESSAGE_TYPES.error },
        "409": { text: "Account already confirmed. Please go to login", type: MESSAGE_TYPES.error },
        "default": { text: "Server error please try again later", type: MESSAGE_TYPES.error },
      },
      model: {
        password: "",
        confirmPassword: "",
      },
    };
  },
  async mounted() {
    const isVerified = await this.API.checkConfirmToken(this.token);
    if (isVerified) {
      this.$router.push({ name: "booking" }); /* If not logged in, will route to login page*/
    }
  },
  methods: {
    async savePassword() {
      let { token } = this;
      let { password } = this.model;

      this.$refs.hubForm.messages["404"] = { text: "Token not found, please contact support.", type: MESSAGE_TYPES.error };
      this.$refs.hubForm.defaultError = "Registration failed, please contact support.";

      if (this.model.password != this.model.confirmPassword) {
        this.$refs.hubForm.error("Passwords do not match");
      } else {
        const result = await this.API.request("/clients/confirm", { method: "POST" }, { token, password });
        this.$refs.hubForm.res = result.status;

        console.info("RES STATUS:", result.status);

        if (result.status >= 200 && result.status <= 299) {
          const res = await result.json();
          let jwt = res.jwt;
          this.$router.push({ name: "booking", params: { jwt } });
        }
      }
    },
  },
};
</script>

<style scoped></style>
