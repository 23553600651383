<template>
    <div class="content-blocks">
        <div v-for="(cb, i) of contentBlocks" :key="i">
            <div v-for="(item, j) of cb.content" :key="i * 100 + j">
                <vue-markdown :source="item.content" />
            </div>
        </div>
    </div>
</template>

<script>
import VueMarkdown from "vue-markdown-render";

export default {
    components: {
        VueMarkdown,
    },
    props: {
        contentBlocks: Array,
    },
};
</script>

<style lang="scss">
.content-blocks {
  img {
    max-width: 80%;
  }
}
</style>
