import { Model } from "@vuex-orm/core";
import Client from "./client";
import Role from "./role";
// import Team from "./team";

export default class User extends Model {
    static entity = "users";

    static fields() {
        return {
            id: this.attr(null),
            email: this.string(),
            username: this.string(),
            confirmed: this.boolean(),
            client: this.hasOne(Client, 'user_id'),
            role: this.hasOne(Role, 'user_id')
        }
    }
}
