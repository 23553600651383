import { gql } from "apollo-boost";

export default gql`
  query {
    xcubes(sort: "name"){
      id
      name
      letter
      location {
        id
        name
      }
    }
  }
`;
