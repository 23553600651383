<template>
  <div id="app">
    <Menu v-if="this.API.loggedIn"></Menu>
    <div :class="$router.currentRoute.meta.admin !== true ? 'page-wrapper' : 'admin-wrapper'">
      <div class="page">
        <keep-alive :include="['adminBookingOverviewComponent', 'operatorBookingOverviewComponent']">
          <router-view></router-view>
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from './shared/Menu.vue'
import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons'

library.add(faBars, faXmark)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
export default {
  name: 'App',
  components: { Menu },
  created() {
    document.title = "XCUBE Hub"
  },
}
</script>

<style lang="scss">
@import "../colors";
html {
  background-color: $color-background;
  background-image: url(../image/bg2.jpg);
  background-size: cover;
  background-position: center center;
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
  margin: auto;
}

h1,
h2,
h3 {
  color:$color-primary;
  font-family: 'Bungee', display;
}

h4,
h5,
h6 {
  color: rgba(255, 255, 0, .6);
  font-family: 'Bungee', display;
}

#app {
  font-family: 'Titillium Web', Helvetica, Arial, Lucida, sans-serif;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $color-text-regular;

  ::-webkit-scrollbar {
    width: 0.5rem;
  }

  ::-webkit-scrollbar-track {
    background: #333;
  }

  ::-webkit-scrollbar-thumb {
    background: $color-primary;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: $color-primary;
  }

  .page-wrapper,
  .admin-wrapper {
    position: absolute;
    top: 80px;
    height: calc(100% - 80px);
    width: 100%;
    overflow-y: auto;

    .page {
      min-width: 90vmin;
      height: 100%;
      min-height: 100%;
      display: grid;
      margin: auto;

      hr {
        width: 60%;
        opacity: 0.4;
        border: 1px solid $color-primary;
      }
    }
  }

  .admin-wrapper {
    .page {
      width: 100%;
    }
  }
}

#app .el-card {
  background-color: black;
  border-width: 0;
  border-radius: 0;
  box-shadow: 0 0 20px black;
}

#app .el-menu.el-menu--horizontal {
  border-width: 0;
}

input.el-input__inner {
  background-color: #222;
  border: none;
  color: #ffff00;
}

input.el-input__inner::placeholder {
  color: rgba(255, 255, 255, 0.2);
}</style>
