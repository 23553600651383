<template>
    <div>
        <div v-if="!loading" class="applications">
            <table>
                <thead class="sticky-header">
                    <tr>
                        <th>Application</th>
                        <th>Type</th>
                        <th>Latest Version</th>
                        <th># Latest Version</th>
                        <th># Older Versions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="application of apps" :key="application.id">
                        <td>
                            <router-link
                                :to="{
                                    name: 'application',
                                    params: { application_id: application.id },
                                }"
                            >
                                <span
                                    v-if="application.experience?.name"
                                    class="application"
                                    >{{ application.experience?.name }}</span
                                >
                                <span v-else class="application"
                                    >#{{ application.id }} Untitled</span
                                >
                            </router-link>
                        </td>
                        <td>
                            {{ application.type }}
                        </td>
                        <td>
                            {{ getLatestVersion(application) }}
                        </td>
                        <td>
                          {{
                                getAllVersions(application)[
                                    getLatestVersion(application)
                                ] || 0
                            }}
                        </td>
                        <td>
                            {{ notLatestVersion(application) }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <spinner :loading="loading" text="Loading applications..."></spinner>
    </div>
</template>

<script>
import api from "../../../api/api";
import Spinner from "../../shared/Spinner.vue";

export default {
    components: { Spinner },
    data() {
        return {
            apps: [],
            loading: false,
        };
    },
    methods: {
        getLatestVersion(application) {
            if (application.x_cube_applications?.length > 0) {
                return application.x_cube_applications?.reduce(
                    (acc, x) => (x.version > acc ? x.version : acc),
                    ""
                );
            }
            return "";
        },
        notLatestVersion(application) {
            const latestVersion = this.getLatestVersion(application);
            if (application.x_cube_applications?.length > 0) {
                const result = application.x_cube_applications.filter(
                    (x) => x.x_cube && x.version !== latestVersion && x.status === "active"
                );
                return result.length;
            }
            return 0;
        },
        getAllVersions(application) {
            if (application.x_cube_applications?.length > 0) {
                return application.x_cube_applications?.reduce((acc, x) => {
                    if (x.status !== "active") {
                        return acc;
                    }
                    if (acc[x.version]) {
                        acc[x.version]++;
                    } else {
                        acc[x.version] = 1;
                    }
                    return acc;
                }, {});
            }
            return {};
        },
    },
    created: async function () {
        this.loading = true;
        this.apps = await api.getApplications();
        this.loading = false;
    },
};
</script>

<style scoped lang="scss">
@import "../../../colors.scss";

.applications {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
    color: $color-text-regular;
    justify-content: center;
    padding: 2rem;
}

.application:hover {
    color: $color-primary;
    cursor: pointer;
}
</style>