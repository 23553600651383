<template>
    <table>
        <thead class="sticky-header">
            <tr>
                <th>Name</th>
                <th class="location-column">Location</th>
                <th class="timezone-column">Time-Zone</th>
                <th class="host-column">Host</th>
                <th>2N</th>
                <th>
                    <span class="mobile-only">EC</span>
                    <span class="desktop-only">ECenter</span>
                </th>
                <th>Ctrl 1</th>
                <th>Ctrl 2</th>
                <th>Ctrl 3</th>
                <th>Ctrl 4</th>
                <th>Ctrl 5</th>
                <th>Ctrl 6</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="xCube of xcubes" :key="xCube.id">
                <td>
                    <span class="mobile-only">
                        {{ xCube.location && xCube.location.name }}
                    </span>
                    <span>
                        {{ xCube.name }}
                    </span>
                    <span class="mobile-only" v-if="xCube.status">
                        {{
                            new Date(
                                xCube.status.updatedAt
                            ).toLocaleDateString()
                        }}
                    </span>
                </td>
                <td class="location-column">
                    {{ xCube.location && xCube.location.name }}
                </td>
                <td class="timezone-column">
                    {{ xCube.location.time_zone }}
                </td>
                <td class="host-column">
                    <span v-if="xCube.status">
                        {{
                            new Date(
                                xCube.status.updatedAt
                            ).toLocaleDateString()
                        }}
                    </span>
                </td>
                <td
                    v-for="name of serviceNames"
                    :key="name"
                    :title="getInfo(xCube, name)"
                >
                    <status-mark
                        v-if="xCube.status"
                        v-bind:checked="hasOnlineStatus(xCube, name)"
                        v-bind:not-checked="hasErrorStatus(xCube, name)"
                    ></status-mark>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import { isNumber } from "../../helpers/math";
import StatusMark from "../shared/StatusMark.vue";

export default {
    components: {
        StatusMark,
    },
    props: {
        xcubes: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            serviceNames: [
                "Door2N",
                "ECenter",
                "Controller1",
                "Controller2",
                "Controller3",
                "Controller4",
                "Controller5",
                "Controller6",
            ],
            isNumber: isNumber,
        };
    },
    methods: {
        hasOnlineStatus(cube, key) {
            if (cube.status && cube.status[key]) {
                return cube.status[key].online && !cube.status[key].error;
            }
            return false;
        },
        hasErrorStatus(cube, key) {
            if (cube.status && cube.status[key]) {
                return !!cube.status[key].error;
            }
            return false;
        },
        getInfo(cube, key) {
            if (cube.status && cube.status[key]) {
                if (cube.status[key].error) {
                    return cube.status[key].error;
                }
                if (cube.status[key].online) {
                    return "No errors";
                }
                return "Offline";
            }
            return "No data";
        },
        /**
         * Method that returns the correct TH string. Used for smaller screens within our responsive design
         * @param {string} name
         * @returns {string}
         */
        formatSubstituteTh(name) {
            if (name.toLowerCase().includes("controller")) {
                return `Ctrl ${this.getControllerNumber(name)}: `;
            } else if (name.toLowerCase().includes("ecenter")) {
                return "eCenter: ";
            }
            return "2N: ";
        },
        /**
         * Method that grabs the controller number and returns it.
         * @param {string} name
         * @returns {integer}
         */
        getControllerNumber(name) {
            let currentRevertedIndex = -2;
            for (let i = 0; i < name.length - 2; i++) {
                if (!this.isNumber(name.slice(currentRevertedIndex))) {
                    return name.slice(currentRevertedIndex + 1);
                }
                currentRevertedIndex -= 1;
            }
        },
    },
};
</script>

<style lang="scss">
@import "../../colors";

.content-tr:hover {
    background-color: rgba($color-background, 0.2);
}

table {
    border: 1px solid #e8de16;
    border-radius: 10px;
    th {
        border-bottom: 1px solid #e8de16;
    }
}

.finish-flag-icon {
    padding-left: 0.5rem;
    color: $color-info;
}

tr:nth-child(odd) {
    background-color: rgba($color-background, 0.8);
}

@media (max-width: 1140px) {
    .timezone-column {
        display: none;
    }
}

@media (max-width: 1024px) {
    .booking-number-column {
        display: none;
    }
}

@media (max-width: 768px) {
    .location-column,
    .host-column {
        display: none;
    }
    .mobile-only {
        display: block;
    }
    .desktop-only {
        display: none;
    }
}
@media (min-width: 768px) {
  .mobile-only {
      display: none;
    }
    .desktop-only {
        display: block;
    }
}

</style>
