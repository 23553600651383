<template>
     <hub-form v-slot:formcontent ref="hubForm" :onSubmit="submit">
          <input placeholder="First name" v-model="member.first" required/>
          <input placeholder="Last name" v-model="member.last" required/>
          <input placeholder="email" type="email" v-model="member.email" required/>
          <button type="submit">Register</button>  
     </hub-form>
</template>

<script>
import HubForm, { MESSAGE_TYPES } from "../shared/Form.vue";

export default {

  components:{
    HubForm
  },
  
  data() {
      return {
        submitted: false,
        message: '',
        teamName: '',
        member: {
            first: '',
            last: '',
            email: '',
            }
        }
  },

  methods: {
    async getBookings() {
        await this.API.getBookings(this.jwt);
    },

    async submit(){

      try{

        this.$refs.hubForm.messages["200-299"] = { text:"Bevestig uw registratie via uw mail.", type: MESSAGE_TYPES.success}
        this.$refs.hubForm.messages["409"] = { text: "Email adres is al in gebruik.", type: MESSAGE_TYPES.error}


        if(this.member.first !== '' && this.member.last !== '' && this.member.email !== '') {
        
        
          const res = await this.API.addToTeam(this.$route.params.token, this.member);

          this.$refs.hubForm.res = res;

        }

      }catch(e){
        throw Error(e)
      }

    },
  },

  async mounted() {
      this.teamName = await this.API.getTeamNameForToken(this.$route.params.token);
  }
}
</script>

<style scoped>

</style>
