import { Model } from "@vuex-orm/core";
import User from "./user";

export default class Role extends Model {
    static entity = "roles";

    static fields() {
        return {
            id: this.attr(null),
            name: this.string(),
            user: this.belongsTo(User, 'user_id'),
            user_id: this.attr(null),
        }
    }
}
