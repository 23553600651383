<template>
    <div>
        <x-cube-overview v-if="!loading" :xcubes="items" />
        <spinner :loading="loading" text="Loading XCubes ..."></spinner>
    </div>
</template>

<script>
import Spinner from "../../shared/Spinner.vue";
import XCubeOverview from "../../xcube/Overview.vue";

export default {
    components: {
        XCubeOverview,
        Spinner,
    },
    mounted() {
        this.fetchItems();
    },
    beforeDestroy() {
        clearInterval(this.i);
    },
    data() {
        return {
            items: [],
            loading: false,
        };
    },
    methods: {
        async fetchItems() {
            this.loading = true;
            this.items = await this.API.getXCubes();
            this.loading = false;
            this.i = setInterval(async () => {
                this.items = await this.API.getXCubes();
            }, 5000);
        },
    },
};
</script>

<style>
</style>