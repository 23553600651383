<template>
    <span>
        <font-awesome-icon v-if="checked" class="checked" icon="fa-solid fa-check-circle" />
        <span v-else>
          <font-awesome-icon v-if="notChecked !== false" class="not-checked" icon="fa-solid fa-xmark-circle" />
          <font-awesome-icon v-else class="unknown" icon="fa-solid fa-question-circle" />
        </span>
    </span>
</template>
  
  <script>

export default {
    props: {
      checked: {
        type: Boolean,
        default: false,
      },
      notChecked: {
        type: Boolean,
        default: null,
      },
    },
  };
  </script>
  
  <style scoped lang="scss">
  @import "../../colors";
  .checked {
    color: $color-success;
  }

  .not-checked {
    color:  $color-danger;
  }

  .unknown {
    color: $color-warning;
  }
  
  </style>