<template>
    <div class="booking-create">
        <div v-if="loading">
            <center>Creating booking...</center>
        </div>

        <hub-form
            v-slot:formcontent
            ref="form"
            :onSubmit="saveBooking"
            class="width-90-create center-content"
        >
            <el-form label-width="160px">
                <div v-if="createdBooking">
                    <h3 class="text-center">Booking created</h3>
                    <show-booking
                        :booking="createdBooking"
                        class="width-90-create"
                    />
                </div>

                <template v-else>
                    <div class="form-wrapper">
                        <span>
                            <h3 class="text-center">Create booking</h3>
                            <el-form-item label="Name">
                                <el-input
                                    placeholder="John Doe"
                                    v-model="booking.name"
                                    required
                                />
                            </el-form-item>
                            <el-form-item label="Email">
                                <el-input
                                    placeholder="john@doe.com"
                                    type="email"
                                    v-model="booking.email"
                                    required
                                />
                            </el-form-item>
                            <el-form-item label="Teamname">
                                <el-input
                                    placeholder="Team Fam Doe"
                                    v-model="booking.team_1_name"
                                    required
                                />
                            </el-form-item>
                            <el-form-item label="Language">
                                <el-select v-model="booking.language" required>
                                    <el-option
                                        v-for="language of [
                                            'nl',
                                            'de',
                                            'en',
                                            'fr',
                                        ]"
                                        :key="language"
                                        :value="language"
                                    />
                                </el-select>
                            </el-form-item>
                            <el-form-item label="Amount of Players">
                                <el-select v-model="booking.team_1_size">
                                    <el-option
                                        v-for="i of [1, 2, 3, 4, 5, 6]"
                                        :value="i"
                                        :key="i"
                                    />
                                </el-select>
                            </el-form-item>
                            <el-form-item label="Event Date">
                                <div class="input-with-text">
                                    <date-picker
                                        v-model="booking.event_date"
                                        :confirm="true"
                                        type="date"
                                        format="DD-MM-YYYY"
                                        placeholder="Select date"
                                        required
                                    />
                                </div>
                            </el-form-item>
                            <el-form-item label="Event Time">
                                <div class="input-with-text">
                                    <date-picker
                                        v-model="booking.event_time"
                                        :confirm="true"
                                        type="time"
                                        format="HH:mm"
                                        placeholder="Select time"
                                        required
                                    />
                                </div>
                                <span class="text-next-to-input"
                                    >Timezone: {{ getTimezoneName() }}</span
                                >
                            </el-form-item>

                            <el-form-item
                                v-if="isOperator || isManagement"
                                label="X-Cube"
                            >
                                <el-select
                                    v-model="booking.xcube_id"
                                    placeholder="X-Cube"
                                    filterable
                                    required
                                >
                                    <el-option-group
                                        v-for="location in locations"
                                        :key="location.id"
                                        :label="location.name"
                                    >
                                        <el-option
                                            v-for="xcube in location.xcubes"
                                            :key="xcube.id"
                                            :value="xcube.id"
                                            :label="`${xcube.letter} - ${xcube.name}`"
                                        />
                                    </el-option-group>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="Experience">
                                <el-select
                                    v-model="booking.experience_id"
                                    placeholder="Experience"
                                    required
                                >
                                    <el-option
                                        v-for="experience in experiences"
                                        :key="experience.id"
                                        :label="experience.name"
                                        :value="experience.id"
                                    />
                                </el-select>
                            </el-form-item>
                            <el-form-item label="">
                                <el-button @click="saveBooking"
                                    >Submit</el-button
                                >
                            </el-form-item>
                        </span>
                    </div>
                </template>
            </el-form>
        </hub-form>
    </div>
</template>
  
<script>
import * as Sentry from "@sentry/vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import dayjs from "dayjs";

import { isManagement, isOperator } from "../../helpers/auth";
import HubForm from "../shared/Form.vue";
import ShowBooking from "./Show.vue";
// import bookingCreateResponse from '../../data/mock/booking-create-response.json'

const createEmptyBooking = () => {
    const code = `HUB-${new Date().getTime()}`;
    return {
        code,
        booking_id: code,
        language: "nl",
        name: "",
        email: "",
        team_1_size: 4,
        team_1_name: "",
        event_date: new dayjs().add(10, "minutes").toDate(),
        event_time: new dayjs().add(10, "minutes").toDate(),
        booking_datetime: new Date(),
        experience_id: null,
        xcube_id: null,
    };
};

export default {
    props: {
        locations: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        ShowBooking,
        HubForm,
        DatePicker,
    },
    data() {
        return {
            loading: false,
            experiences: [],
            booking: this.$route.params.booking
                ? this.formatReceivedBooking()
                : createEmptyBooking(),
            createdBooking: null,
        };
    },
    async mounted() {
        this.experiences = await this.API.getExperiences();
    },
    computed: {
        isManagement() {
            return isManagement(this?.API?.user);
        },
        isOperator() {
            return isOperator(this?.API?.user);
        },
    },
    methods: {
        getTimezoneName() {
            // Get the current timezone name
            const timezoneName =
                Intl.DateTimeFormat().resolvedOptions().timeZone;
            return timezoneName;
        },

        async saveBooking() {
            const time = dayjs(this.booking.event_time);
            this.booking.event_datetime = dayjs(this.booking.event_date)
                .hour(time.hour())
                .minute(time.minute())
                .toDate();
            try {
                this.loading = true;
                const newBooking = await this.API.createBooking(this.booking);
                const data = await this.API.getBooking(newBooking.id);
                this.createdBooking = data;
                this.$refs.form.success("Booking created");
                this.booking = createEmptyBooking();
            } catch (e) {
                this.$refs.form.error(e.message);
                Sentry.captureException(e);
            } finally {
                this.loading = false;
            }
        },

        formatReceivedBooking() {
            const receivedBooking = this.$route.params.booking;
            const code = `HUB-${new Date().getTime()}`;
            const result = {
                code,
                booking_id: code,
                language: receivedBooking.language,
                name: `${receivedBooking.client.first_name} ${receivedBooking.client.last_name}`,
                email: receivedBooking.client.email,
                team_1_size: receivedBooking.player_count,
                team_1_name: receivedBooking.team.name,
                event_date: new Date(receivedBooking.date),
                event_time: new Date(receivedBooking.date),
                booking_datetime: new Date(),
                experience_id: Number.parseInt(receivedBooking.experience.id),
                xcube_id: Number.parseInt(receivedBooking.xcube.id),
            };
            return result;
        },

        /**
         * Method that hides the date picker table so that it doesn't mess up the time picker table.
         * Sets the attribute hidden once this method has been triggered.
         * There is no need to remove the hidden attribute because when a new date picker is opened it's a completely new render.
         * @returns {void}
         */
        toggleDatePickerTable() {
            let dateTable = document.getElementsByClassName("mx-table-date")[0];
            dateTable.classList.add("mx-hideElement");
        },
    },
};
</script>

<style lang="scss">
@import "../../colors.scss";

.form-container {
    display: flex;
    align-items: center;
    flex-direction: column;

    form:not(.el-form) {
        width: max(50%, 750px);
    }
    form.el-form {
        width: 100%;
    }
}
.text-next-to-input {
    color: $color-primary;
    text-align: left;
    display: block;
}

.el-select {
    width: 100%;
}

.input-with-text {
    display: flex;
}

@media screen and (max-width: 800px) {
    .form-container {
        form:not(.el-form) {
            width: 90%;
        }
    }
    .el-form-item {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .el-form-item__label {
        text-align: center !important;
    }

    .el-form-item__content {
        margin-left: 0px !important;
        width: min(95%, 400px);
    }

    .el-select {
        width: 100%;
    }

    .input-with-text {
        justify-content: center;
    }
    .text-next-to-input {
        text-align: center;
    }
}
</style>