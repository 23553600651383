import Vue from 'vue'
import api from './api'

export {default as api} from './api';
export {store} from './orm';

Vue.mixin({
    computed: {
        API() {
            return api;
        }
    }
})  