<template>
  <div>
    <div id="navbar-menu" :class="`${navbarInteraction ? navbarCollapsed ? 'navbar-bg-deactivated' : 'navbar-bg-activated' : ''}`">
      <div id="navbar-logo-container">
        <span class="navbar-logo-wrapper">
          <a @click="isManagement ? closeNavbar('admin-bookings') : isOperator ? closeNavbar('operator-bookings') : closeNavbar('bookings')">
              <img class="logo" src="/logo.png" />
          </a>
          <span v-if="isTestEnvironment" class="test-environment-text">Test Env</span>
          <span>{{ appVersion }}</span>
        </span>
      </div>

      <div id="navbar-content-container" :class="`${navbarCollapsed ? 'navbar-hide' : 'navbar-show'}`">
        <template v-for="linkObject of links">
          <span v-for="[routeName,name] of Object.entries(linkObject)" :key="routeName" :class="`${routeName == selectedRoute ? 'selected-nav-link' : ''} ${!navbarCollapsed ? 'navbar-item-show-border' : ''} navbar-item`">
            <a @click="closeNavbar(routeName)" class="navbar-item-content">{{ name }}</a>
          </span>

        </template>

        <span style="flex-grow: 1;"></span>
        <span :class="`${!navbarCollapsed ? 'navbar-item-show-border' : ''} navbar-item`">
          <a @click="logout" class="navbar-item-content">Logout</a>
        </span>
      </div>

      <div id="navbar-hamburger-button-container" :class="`${navbarCollapsed ? 'navbar-align-justify-icon' : 'navbar-justify-icon'}`">
        <span v-if="navbarCollapsed" @click="toggleNavbarVisibility" class="navbar-icon-wrapper">
          <font-awesome-icon icon="fa fa-bars" class="resize-navbar-icon"/>
        </span>
        <span v-else @click="toggleNavbarVisibility" class="navbar-icon-wrapper">
          <font-awesome-icon icon="fa fa-xmark" class="resize-navbar-icon"/>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { isManagement, isOperator, isTestEnvironment } from '../../helpers/auth';
import { version } from "../../../package.json";


export default {
  data() {
    return {
      activeIndex: "bookings",
      navbarCollapsed:true,
      navbarInteraction: false,
      isTestEnvironment: false,
      /*
        the links arrays contain the objects with the key being the route name and the value representing the name
        Each object is a new route name/name on the navbar. Each user middleware type has its own array with link objects.
      */
      managerLinks:[
        {"admin-bookings":"Bookings"},
        {"admin-bookings-create": "Create Booking"},
        {"admin-xcubes":"X-Cubes"},
        {"applications":"Applications"},
        {"admin-xcube-control":"X-Cube-Control"},
        {"admin-tools":"Tools"},
        {"general-information":"General Information"},
        {"site-information":"Site Information"}
      ],
      operatorLinks:[
        {"operator-bookings":"Bookings"},
        {"operator-bookings-create":"Create Booking"},
        {"general-information":"General Information"},
        {"site-information":"Site Information"}
      ],
      userLinks:[
        {"bookings":"Bookings"}
      ],
    };
  },
  mounted(){
    this.isTestEnvironment = isTestEnvironment();
  },
  computed: {
    links () {
      if (this.isManagement) {
        return this.managerLinks
      } else
      if (this.isOperator) {
        return this.operatorLinks
      } else
      {
        return this.userLinks
      }
    },
    selectedRoute() {
        return this.$route.name;
    },
    isManagement() {
      return isManagement(this?.API?.user);
    },
    isOperator() {
      return isOperator(this?.API?.user);
    },
    appVersion() {
      return version;
    }
  },
  methods: {
    toggleNavbarVisibility(){
      this.navbarInteraction = true;
      this.navbarCollapsed ? this.navbarCollapsed = false : this.navbarCollapsed = true;
    },
    closeNavbar(routeName){
      this.navbarCollapsed = true;
      if (this.$route.name !== routeName) {
        this.$router.push({name: routeName});
      }
    },
    logout() {
      this.API.logout();
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../colors";

.resize-navbar-icon{
  height: 2em;
  margin-top:10px;
}
.selected-nav-link{
  border-bottom: 1.5px solid yellow;
}

a:hover{
  cursor:pointer;
}

.logo {
  height: 30px;
  width: 30px;
  margin: 0 16px;
  margin-top: 8px;
}

.dock-right {
  position: absolute;
  right: 0px;
}

.el-menu-item.is-disabled {
  opacity: 1;
  cursor: initial;
}

.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

#navbar-menu{
  background-color:black;
  height:50px;
  padding-top:7px;
  margin-left:7px;
  width:100%;
  display:flex;
  margin-left:50%;
  transform:translateX(-50%);
  background-color:none;
} 

#navbar-logo-container{
  flex: 0.1 1 0;
}

#navbar-content-container{
  display:flex;
  // flex: 0.6 1 0;
  justify-content: space-between;
  width: 100%;
}

#navbar-hamburger-button-container{
  flex: 1 1 0;
  visibility: hidden;
}

.navbar-item,
.navbar-logo-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $color-primary
}

.navbar-item-content, .navbar-item-content:hover{
  color:#C2C2C2;
  white-space: nowrap;
  padding: 0 20px;
  cursor: pointer;
  caret-color: transparent;
}

.navbar-icon-wrapper:hover{
  cursor:pointer;
}

@media screen and (max-width:992px){

  .navbar-icon-wrapper{
    position:absolute;
    top:0;
  }
  #navbar-menu{
    position:absolute;
    z-index:99;
    &.navbar-bg-activated {
      height:auto;
      background-color:black;
      height:100%;
      width:100%;
      animation-name:showNavbar;
      animation-duration:0.5s;
    }

    &.navbar-bg-activated > #navbar-content-container{
      animation-name:fadeIn;
      animation-duration: 2s;
    }

    &.navbar-bg-deactivated{
      animation-name: removeNavbar;
      animation-duration: 0.5s;
      width: 100vw;
    }
  }

  #navbar-logo-container{
    flex: 1 0 0; 
    .navbar-logo-wrapper {
      flex-direction: row;
    }
  }
  
  #navbar-content-container{
    flex-direction: column;
    flex: 3 1 0;
    &.navbar-hide{
      visibility:hidden;
    }
    &.navbar-show{
      justify-content: start;
    }
  }

  #navbar-hamburger-button-container > *{
    visibility: visible;
  }

  .navbar-item{
    flex: 0 0 0;
    padding-top:20px;
    padding-bottom:20px;
    &.navbar-item-show-border{
      border-bottom: 1px solid $color-text-regular;
    }
  }

  .navbar-align-justify-icon{
    display:flex;
    justify-content:center;
    align-items:center;
  }

  .navbar-justify-icon{
    display:flex;
    justify-content: center;
  }
}

/**Start keyframe animations */
@keyframes showNavbar{
  from {
    height:0%;
  }
  to {
    height:100%;
  }
}

@keyframes removeNavbar{
  from {
    height: 100%;
    background-color:black;
  }
  to {
    height: 0%;
    background-color:black;
  }
}

@keyframes fadeIn{
  from {
    opacity:0;
  }
  to {
    opacity: 1;
  }
}

</style>
