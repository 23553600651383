import { Model } from "@vuex-orm/core";
import Xcube from "./xcube";

export default class Location extends Model {
  static entity = "locations";

  static fields () {
    return {
      id: this.attr(null),      
      country: this.string(),
      name: this.string(),
      address: this.string(),
      zip_code: this.string(),
      province: this.string(),
      phone_number: this.string(),
      email: this.string(),
      extra_costs_per_hour: this.number(),
      created_at: this.string(),
      updated_at: this.string(), 
      xcubes: this.hasMany(Xcube, 'id'),
    };
  }
}
