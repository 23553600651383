<template>
    <div v-if="locations">
        <div class="header">
            <h1>
                Site Information
                <span v-if="locations.length === 1">{{
                    locations[0].name
                }}</span>
            </h1>
            <div class="location-select" v-if="locations.length > 1">
                <v-select
                    v-model="selected.location"
                    :options="locations"
                    :clearable="false"
                    label="name"
                ></v-select>
            </div>
        </div>
        <div v-if="selected.location">
            <div v-if="selectedContentBlocks?.length > 0">
                <render-content-blocks
                    :content-blocks="selectedContentBlocks"
                />
            </div>
            <div v-else>
                <p>No specific information for {{ selected.location.name }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import api from "../../api/api";
import RenderContentBlocks from "../content-block/RenderContentBlocks.vue";
export default {
    components: {
        RenderContentBlocks,
    },
    async mounted() {
        if (!api.user.locations) {
            await api.fetchUserData();
        }
        this.locations = api.user.locations;
        if (this.locations.length === 1) {
            this.selected.location = this.locations[0];
        }
    },
    watch: {
        async 'selected.location'(location) {
          const locationId = location.id;
            if (!this.locationContentBlocks[locationId]) {
                const contentBlocks = await api.getContentBlocks(
                    "operator-info",
                    locationId
                );
                this.$set(this.locationContentBlocks, locationId, contentBlocks);
            }
        },
    },
    computed: {
        selectedContentBlocks() {
            if (!this.selected.location) {
                return [];
            }
            return this.locationContentBlocks[this.selected.location.id];
        },
    },
    data() {
        return {
            locations: null,
            selected: {
              location: null,
            },
            locationContentBlocks: {},
        };
    },
};
</script>

<style lang="scss" scoped>
.header {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}
.location-select {
  width: 350px;
  padding-left: 2%;
}
</style>
