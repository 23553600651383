<template>
  <hub-form class="form" messageBox="absolute" v-slot:formcontent ref="hubForm" :onSubmit="savePassword">
      <h2>Enter your email</h2>
      <input placeholder="email" type="email" v-model="model.email" maxLength="64"  minLength="5" required/>
      <button @submit.prevent>Reset password</button>
      <router-link to="/login">
        Back to login
      </router-link> 
  </hub-form>
</template>

<script>
import HubForm from "../shared/Form.vue";


export default {
  components:{
    HubForm
  },
  data() {
    return { 
      model: {
        email: ""
      },
      loading: false,
      }
  },
  methods: {
    async submitEmail() {
      
    },
    async savePassword() {
      let {email} = this.model
      this.loading = true;
      try{
        const res = await this.API.request('/clients/forgot-password', {method: 'POST'}, {
          email: email,
        })

        if(!res.ok) {
          setTimeout(() => {
            this.loading = false;
            this.$refs.hubForm.error(`Request failed, status: ${res.status}`)

          }, Math.random() * 1500);
        } else {
          this.loading = false;
          this.$refs.hubForm.success("Request send. Please check your email.");
          this.$router.push('/login')
        }
      }catch(e){
        console.error(e)
        this.$refs.hubForm.error(`Failed to contact the server, please try again later`)
      }
      
      
    }
  }
}
</script>

<style scoped lang="scss">
.form{
  background-color:black;
  display:flex;
  margin:auto;
  min-width: 340px;
  min-height: 420px;

  box-shadow: 0 0 20px black;
  border-radius: 5px;

  input{
    width:74%;
    margin:auto;
  }

  button, h2{
    width:84%;
    margin:auto;
  }

  h2{
    margin: 50px auto 10px auto;
  }

  button{
    margin: 60px auto 10px;
  }

}


.login {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-button, .back-button {
  width: 100%;
  margin-top: 40px;
}
.login-form {
  width: 290px;
}
.forgot-password {
  margin-top: 10px;
}
</style>
