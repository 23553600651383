<template>
    <div class="container">
      <div v-if="!submitted" class="form-container">
        <div :class="`message-box ${messageStr ? '' : 'hidden'} ${MESSAGE_TYPES[messageType]} ${MESSAGE_BOX_CLASS[$props.messageBox]}`">
          <!--<span>&#9888;</span>-->
          <span class="warning-icon">{{ messageStr }}</span>
        </div>
        <form ref="form" v-on:submit.prevent="">
          <slot name="formcontent"></slot>
        </form>
      </div>
      <div v-else class="submitted-container">
        {{ messageStr }}
      </div>
    </div>
  </template>
  
  <script>
  import * as Sentry from "@sentry/vue";

  const MESSAGE_BOX_CLASS = {
    none: "none",
    "above-form": "above-form",
    absolute: "absolute",
  };
  
  export const MESSAGE_TYPES = {
    message: "message",
    success: "success",
    error: "error",
  };
  
  export default {
    props: {
      onSubmit: {
        type: Function,
        default: null,
      },
      messages: {
        type: Object,
      },
      messageBox: {
        type: String,
        validator: (value) => {
          return Object.keys(MESSAGE_BOX_CLASS).includes(value);
        },
        default: "above-form",
      },
    },
    
    data() {
      return {
        MESSAGE_BOX_CLASS,
        MESSAGE_TYPES,
        defaultError: "An error occurred, please try again later",
        messageStr: "",
        messageType: MESSAGE_TYPES.message,
        submitted: false,
        res: null,
      };
    },
    
    mounted() {
      this.$el.addEventListener("submit", this.submit);
    },

    beforeDestroy() {
      this.$el.removeEventListener("submit", this.submit);
    },
  
    methods: {
        async submit() {
            //Pre-submit
            if (!this.$refs.form.checkValidity()) {
                //submit an invalid form for built-in html popups (won't actually submit)
                this.$refs.form.submit();
                return;
            }
    
            try {
                if (this.onSubmit) {
                    await this.onSubmit();
                }
            } catch (e) {
                this.res = "default";
                console.error(e);
            }
    
            //Post-submit
        },
    
        getMessage(res) {
            //Checks for direct res in the object
            if (this.messages && this.messages[res]) {
                return this.messages[res];
            }

            //Checks if res falls in between ranges
            let rangeRes = Object.entries(this.messages)
                .filter((i) => {
                    const [k] = i;
                    return k.includes("-");
                })
                .filter((i) => {
                    const [k] = i;
                    const [min, max] = k.split("-");
                    return parseInt(res) >= parseInt(min) && parseInt(res) <= parseInt(max);
                });
    
            if (rangeRes.length) {
                const [, v] = rangeRes[0];
                return v;
            }
    
            //fallback default
            Sentry.captureMessage(`No Error message for ${res}`);
            return this.messages["default"];
        },
        message(str) {
            this.messageType = MESSAGE_TYPES.message;
            this.messageStr = str;
        },
        success(str) {
            this.messageType = MESSAGE_TYPES.success;
            this.messageStr = str;
        },
        error(str) {
            this.messageType = MESSAGE_TYPES.error;
            this.messageStr = str;
        },
    },
    watch: {
      res: function(newVal) {
        if (newVal) {
          const resMessage = this.getMessage(newVal.toString());
          this.messageType = resMessage.type;
          this.messageStr = resMessage.text;
  
          if (newVal >= 200 && newVal <= 299) {
            this.submitted = true;
          } else {
            console.error(`Form response: status code ${newVal}`);
          }
        } else {
          this.messageStr = "";
        }
      },
    },
  };
</script>
  
<style lang="scss" scoped>
    form:not(.el-form) {
        padding: 10px;
        background: rgba(0,0,0,0.4);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        .el-input,
        .el-select {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    
        input {
            height: 35px;
            margin: 5px;
            padding-left: 15px;
            padding-right: 15px;
        
            background-color: #ffffff24;
            border: 1px solid yellow;
            border-radius: 5px;
        
            font-size: 13px;
            color: white;
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 1px;
        
            &::placeholder {
                color: #ffffff5c;
                opacity: 1;
            }
        
            &:focus {
                outline: none;
            }
        }
    
        button,
        input[type="submit"] {
            height: 35px;
            margin: 5px;
            padding-left: 15px;
            padding-right: 15px;
        
            color: black;
            background-color: yellow;
            border: 1px solid yellow;
            border-radius: 5px;
            outline: none;
        
            font-size: 13px;
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 1px;
        }
    }
    
    .form-container {
        width: 100%;
        height: 100%;
    }
    
    .message-box {
        background-color: #007c89;
        color: #00e7ff;
    
        min-height: 50px;
        margin: 20px 0;
    
        border-radius: 5px;
        border: 1px solid #00e7ff;
    
        display: flex;
        
        &.hidden {
            visibility: hidden;
        }
        
        > span {
            margin: auto;
            width: calc(100% - 80px); /* because the icon is 40px absolute, so that the text is centered better*/
        }
        
        &.none {
            display: none;
        }
        
        &.absolute {
            position: absolute;
            margin: 5px;
            top: 0;
            left: 30%;
            right: 30%;
        }
        
            /* MessageBox Types*/
        &.error {
            background-color: #780116;
            color: #ff002d;
            border: 1px solid #ff002d;
            }
        &.error::before {
            content: "\26A0";
            margin: auto 10px;
            font-size: 30px;
            position: absolute;
        }
        
        &.message {
            background-color: #007c89;
            color: #00e7ff;
            border: 1px solid #00e7ff;
        }
        &.message::before {
            content: "\1F6C8";
            margin: auto 10px;
            font-size: 30px;
            position: absolute;
        }
        
        &.success {
            background-color: #044400;
            color: #10ff00;
            border: 1px solid #10ff00;
        }
        &.success::before {
            content: "\2714";
            margin: auto 10px;
            font-size: 30px;
            position: absolute;
        }
    }
</style>
  