import Vue from "vue";
import queryString from 'query-string';
import fetchRetry from "fetch-retry";
import { options as gqlOptions } from "./orm";
import user from "./queries/user";
import booking from "./queries/booking";
import applications from "./queries/applications";
import application from "./queries/application";
import { ApolloClient, InMemoryCache, ApolloLink, HttpLink } from 'apollo-boost';
import xcubes from "./queries/xcubes";

// You should use an absolute URL here
const httpLink = new HttpLink({ uri: `${import.meta.env.VITE_APP_API_URL}/graphql` });

const fetchWithRetry = fetchRetry(fetch, {
  retries: 5,
  retryDelay: 1000 // 2 seconds
});

const errorLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    if (response.errors && response.errors.find(error => (error.message.toLowerCase() === "invalid token."))) {
      this.logout();
      throw new Error("Your token has expired. Please login again.");
    }
    return response;
  })
});

const apolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink, httpLink]),
  cache: new InMemoryCache(),
});

function getToken() {
  return localStorage.getItem("xcube-session-key");
}

function parseJwt() {
  const token = getToken();
  if (!token) return {};
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const repl = (c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(repl)
      .join("")
  );
  return JSON.parse(jsonPayload);
}

function getApolloOptions() {
  return {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
    context: {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    },
  };
}

export default new Vue({
  data() {
    return {
      defaultOptions: {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" },
        redirect: "follow",
        referrerPolicy: "no-referrer",
      },
      HOST: import.meta.env.VITE_APP_API_URL,
      user: null,
      isManagement: false,
    };
  },
  computed: {
    loggedIn() {
      return !!this.user;
    }
  },
  methods: {
    async getSummary(token) {
      const response = await fetchWithRetry(`${this.HOST}/bookings/summary/${token}`);
      return response.json();
    },

    initReactiveUser(session) {
      this.session = session;
    },

    async getTeamNameForToken(token) {
      const path = `/teams/invite/${token}`;
      const options = this.getOptions();
      const result = await this.request(path, options);
      const tmp = await result.json();
      return tmp.name;
    },

    async addToTeam(token, data) {
      const path = `/teams/invite/${token}`;
      const options = this.getOptions({ method: "POST" });
      let res = await this.request(path, options, data);
      await res.json();
      return res.status;
    },

    async getTeamOwner(token) {
      const path = `/teams/register/${token}`;
      const options = this.getOptions();
      const result = await this.request(path, options);
      const tmp = await result.json();
      return tmp;
    },

    async registerTeamOwner(token, data) {
      const path = `/teams/register/${token}`;
      const options = this.getOptions({ method: "POST" });
      let res = await this.request(path, options, data);
      await res.json();
      return res.status;
    },

    async getUserBookings() {
      const path = `/userbookings?_order=date:DESC`;
      const options = this.getOptions();
      const result = await this.request(path, options);
      const tmp = await result.json();
      return tmp;
    },

    async getBookingTable(params = undefined) {
      const path = `/bookings?${params ? queryString.stringify(params) : ""}`;
      const options = this.getOptions();
      const res = await this.request(path, options);
      const data = await res.json();
      if (data.statusCode >= 400) {
        throw new Error(data.message);
      }
      return data;
    },

    async getBooking(id) {
      if (!id) {
        throw new Error(`No id specified`);
      }
      const path = `/bookings/${id}`;
      const options = this.getOptions();
      const res = await this.request(path, options);
      const data = await res.json();
      if (data.statusCode >= 300) {
        throw new Error(data.message);
      }
      return data;
    },

    async getLocations(params = "") {
      const path = `/locations?${params}`;
      const options = this.getOptions();
      let res = await this.request(path, options);
      const locations = await res.json();
      return locations;
    },

    async getUserLocations(params = undefined) {
      const path = `/locations/mine${params ? queryString.stringify(params) : ""}`;
      const options = this.getOptions();
      let res = await this.request(path, options);
      const locations = await res.json();
      return locations;
    },

    async getXCubes() {
      const path = `/xcubes?_sort=name`;
      const options = this.getOptions();
      const res = await this.request(path, options);
      const xCubes = await res.json();
      // TODO this is only necessary since location is not mandatory 
      // for a xcube and there are some xcubes without a location.
      // Once this is fixed this can be removed
      const cleanedXCubes = xCubes.map((xc) => {
        if (xc.location) {
          return xc;
        }
        return {
          ...xc,
          location: {
            name: "?",
            id: null,
            time_zone: "?",
            address: "?",
            zipcode: "?",
          }
        }
      })
      return cleanedXCubes;
    },

    async getXCubesSimple() {
      const res = await apolloClient.query({
        ...getApolloOptions(),
        query: xcubes,
      });

      return res.data.xcubes;
    },

    async getExperiences() {
      const path = "/experiences?_sort=name";
      const res = await this.request(path, this.getOptions());
      return await res.json();
    },

    async getContentBlocks(key, locationId = null) {
      let path = `/content-blocks?key=${key}`;
      if (locationId != null) {
        path += `&location.id=${locationId}`;
      } else {
        path += `&location.id_null=1`;
      }
      const options = this.getOptions();
      const res = await this.request(path, options);
      return await res.json();
    },

    async createBooking(booking) {
      const path = "/api/bookings";
      const res = await this.request(
        path,
        this.getOptions({
          method: "POST",
          body: JSON.stringify(booking),
        })
      );
      if (!res.ok) {
        const text = await res.text();
        if (res.status === 400 && text.toLowerCase().includes("one or more bookings at this time already exist")) {
          throw new Error("conflict");
        }
        throw new Error(text);
      }
      return res.json();
    },

    async deleteBooking(booking) {
      const deleted_at = new Date().toISOString();
      const result = await this.updateBooking(booking.id, { deleted_at });
      return {
        ...booking,
        deleted_at: result.deleted_at,
      }
    },

    async moveBooking(originalBooking, newBooking) {
      const createdBooking = await this.createBooking(newBooking);
      await this.deleteBooking(originalBooking);
      return createdBooking;
    },


    async updateXCubeApplication(id, xCubeApp) {
      const path = `/x-cube-applications/${id}`;
      const res = await this.request(
        path,
        this.getOptions({
          method: "PUT",
          body: JSON.stringify(xCubeApp),
        })
      );
      return {
        json: await res.json(),
        status: res.status,
      };
    },

    async updateXCube(id, xCube) {
      const path = `/xcubes/${id}`;
      const res = await this.request(
        path,
        this.getOptions({
          method: "PUT",
          body: JSON.stringify(xCube),
        })
      );
      return {
        json: await res.json(),
        status: res.status,
      };
    },

    getHeaders() {
      const token = getToken();

      const headers = {
        "Content-Type": "application/json",
      };
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
      return headers;
    },

    getOptions(overwrites = {}) {
      const options = Object.assign({}, this.defaultOptions);
      options.headers = this.getHeaders();
      return Object.assign({}, options, overwrites);
    },

    async request(path, options = {}, data = undefined) {
      if (data) options.body = JSON.stringify(data);
      options = this.getOptions(options);
      const response = await fetch(this.HOST + path, options);
      if (response.status === 403 || response.status === 401) {
        this.logout();
        throw new Error("Invalid Token");
      }
      return response;
    },

    async login(credentials) {
      const path = "/auth/local";
      const options = this.getOptions({ method: "POST" });

      const result = await this.request(path, options, credentials);
      const json = await result.json();

      if (result.status !== 200) {
        throw new Error("Unknown username/password");
      }
      localStorage.setItem("xcube-session-key", json.jwt);
      gqlOptions.headers.Authorization = `Bearer ${json.jwt}`;
      await this.fetchUserData();
      return result.status;
    },

    async checkConfirmToken(token) {
      const options = this.getOptions({ method: "GET" });
      const path = `/clients/isVerified?activation_token=${token}`
      const response = await fetch(this.HOST + path, options);
      
      if (response.ok) {
        const { isVerified } = await response.json();
        return isVerified;
      }
      return false;
    },


    async logout() {
      localStorage.removeItem("xcube-session-key");
      this.user = null;
      window.location.reload();
    },

    async fetchUserData() {
      if (!getToken()){
        return null;
      }
      let { id } = parseJwt();
      if (!id) {
        return null;
      }
      const res = await apolloClient.query({
        ...getApolloOptions(),
        query: user,
        variables: { id },
      });
      this.user = res.data.user;
      return res.data.user;
    },

    async fetchBookingData(id) {
      const res = await apolloClient.query({
        ...getApolloOptions(),
        query: booking,
        variables: { id }
      })
      return res.data.booking
    },

    async getApplications() {
      const res = await apolloClient.query({
        ...getApolloOptions(),
        query: applications,
      });

      return res.data.applications;
    },

    async getApplication(id) {
      const res = await apolloClient.query({
        ...getApolloOptions(),
        query: application,
        variables: { id }
      });

      return res.data.application;
    },

    async getApplicationRest(id) {
      const path = `/applications/${id}`;
      let options = this.getOptions();
      const result = await this.request(path, options);
      return await result.json();
    },

    async getBookings() {
      const path = "/bookings/next-bookings";
      let options = this.getOptions();
      const result = await this.request(path, options);
      return result;
    },

    async updateBooking(id, booking) {
      const path = `/bookings/${id}`;
      const res = await this.request(path,
        this.getOptions({
          method: "PUT",
          body: JSON.stringify(booking)
        })
      );
      return await res.json();
    },

    async getCustomersCSV() {
      const path = `/export/customers`;
      const res = await this.request(path,
        this.getOptions({
          method: "GET",
        })
      );
      return res.json();
    },
  },
});
