import { gql } from "apollo-boost";

export default gql`
  query {
    xcubes {
      location {
        name
      }
    }
    applications {
      id
      type
      experience {
        name
      }
      x_cube_applications {
        id
        status
        version
        x_cube {
          id
          name
        }
      }
    }
  }
`;
