import { Model } from "@vuex-orm/core";
import Team from "./team"
import User from "./user"


export default class Client extends Model {
  static entity = "clients";
  static eagerLoad = ['teams'];

  static fields () {
    return {
      id: this.attr(null),
      username: this.string(),
      first_name: this.string(),
      insertion: this.string(),
      last_name: this.string(),
      date_of_birth: this.string(),
      gender: this.string(),
      email: this.string(),
      email_verified: this.boolean(false),
      address1: this.string(),
      address2: this.string(),
      zip_code: this.string(),
      place_name: this.string(),
      country: this.string(),
      phone_number: this.string(),
      registration_date: this.string(),
      points: this.number(),
      activation_token: this.string(),
      teams: this.belongsTo(Team, 'team_id'),
      user: this.belongsTo(User, 'user_id'),
      user_id: this.attr(null),
      created_at: this.string(),
      updated_at: this.string(),
    };
  }
}