<template>
    <hub-form v-slot:formcontent ref="hubForm" :onSubmit="savePassword">
      <input placeholder="password" type="password" v-model="model.password" maxLength="32"  minLength="5" required/>
      <input placeholder="confirm password" type="password" v-model="model.confirmPassword" maxLength="32"  minLength="5" required/>
      <button @submit.prevent>Reset password</button>  
    </hub-form>
</template>

<script>
import HubForm from "../shared/Form.vue";

export default {
  components:{
    HubForm
  },

  data() {
    const token = this.$route.params.token
    return { 
      token, 
      model: {
        code: token,
        password: "",
        confirmPassword: ""
      },
    }
  },
  methods: {
    async savePassword() {
      let {token } = this
      let {password} = this.model
      
      if(this.model.password != this.model.confirmPassword){
        this.$refs.hubForm.error("Wachtwoorden komen niet overeen");
      } else {
        const result = await this.API.request('/auth/reset-password', {method: 'POST'}, {
          code: token,
          password: password,
          passwordConfirmation: password,
        })
        this.$refs.hubForm.res = result.status;

        if(result.status >=200 && result.status < 299) {
          this.$refs.hubForm.success("Your passsword is updated.");
          const res =  await result.json()
          let jwt = res.jwt;
          this.$router.push({name: 'booking', params: { jwt }})
        } else {
          this.$refs.hubForm.error("Could not change password, please contact support.");
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
