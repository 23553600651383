import Vue from 'vue'
import Vuex from 'vuex'
import VuexORM from '@vuex-orm/core'
import VuexORMGraphQL from '@vuex-orm/plugin-graphql'
import CustomAdapter from './vuex-orm-adapter'

import Booking from './models/booking'
import Client from './models/client'
import Experience from './models/experience'
import Location from './models/location'
import Team from './models/team'
import User from './models/user'
import Xcube from './models/xcube'
import Role from './models/role'
import { isTestEnvironment } from '../helpers/auth'

Vue.use(Vuex)

const database = new VuexORM.Database()

database.register(Booking)
database.register(User)
database.register(Role)
database.register(Client)
database.register(Experience)
database.register(Location)
database.register(Team)
database.register(Xcube)

export const options = {
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only'
      }
    },
    headers: {
  
    },
    database: database,
    url: import.meta.env.VITE_APP_API_URL + '/graphql',
    includeExtensions: true,
    debug: isTestEnvironment(),
    adapter: new CustomAdapter()
  }
  
  const token = localStorage.getItem('xcube-session-key');
  if (token) {
    options.headers = {
      Authorization: `Bearer ${token}`
    }
  }
  
  VuexORM.use(VuexORMGraphQL, options);
  
  export const plugins = [VuexORM.install(database)];
  
  export const store = new Vuex.Store({plugins});
  
