import Vue from 'vue'
import VueRouter from 'vue-router'
import router from './config/router'
import ElementUI from 'element-ui'
import vSelect from "vue-select";
import * as Sentry from "@sentry/vue";
import locale from 'element-ui/lib/locale/lang/nl'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
    faFacebookSquare,
    faInstagramSquare,
    faLinkedin,
} from '@fortawesome/free-brands-svg-icons'
import {
    faShare,
    faVideo,
    faDownload,
    faCircleCheck,
    faCircleXmark,
    faFlagCheckered,
    faCircleQuestion,
    faBuilding,
    faGamepad,
    faArrowLeft,
} from '@fortawesome/free-solid-svg-icons'
import VueGtag from "vue-gtag";
import "/node_modules/flag-icons/css/flag-icons.min.css";

import App from './components/App.vue'
import './theme.scss'
import './main.scss'
import { store } from './api'

library.add(
    faFacebookSquare,
    faInstagramSquare,
    faLinkedin,
    faShare,
    faVideo,
    faDownload,
    faCircleCheck,
    faCircleXmark,
    faFlagCheckered,
    faCircleQuestion,
    faBuilding,
    faGamepad,
    faArrowLeft,
);
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component("v-select", vSelect);
Vue.config.productionTip = false

window.addEventListener('unhandledrejection', function(event) {
    console.error(`Unhandled rejection: ${event.reason}`);
    event.preventDefault();
});
  

Vue.use(VueRouter)
Vue.use(ElementUI, { locale });
Vue.use(VueGtag, {
    config: {
        id: import.meta.env.VITE_APP_GOOGLE_ANALYTICS_ID,
    },
}, router);


Vue.mixin({
    computed: {
        hubUrl() {
            return import.meta.env.VITE_APP_PUBLIC_PATH
        }
    }
})

Sentry.init({
    Vue,
    dsn: "https://4c44e49ef5b4d8390d96f19f111d5828@o4506653921247232.ingest.us.sentry.io/4507531388387328",
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.httpClientIntegration(),
        Sentry.replayIntegration(),
    ],

    beforeSend: (event) => {
        if (!import.meta.env.DEV) {
            return event;
        }
        console.error("***SENTRY ERROR***:", event);
    },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});


const render = h => h(App)
new Vue({ render, router, store }).$mount('#app')
